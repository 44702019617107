import React, { Fragment, useContext, useState, useEffect } from 'react';
import AppContext from '../../context/Context';
import { Row, Col, UncontrolledTooltip,  CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import orderBy from "lodash/orderBy";
import { isIterableArray, capitalize } from '../../helpers/utils';
import MarketNewConnections from './../dashboard-alt/MarketNewConnections.js'
import MarketInsolvencies from './../dashboard-alt/MarketInsolvencies.js'
import NewContractorsDashboard from '../dashboard-alt/NewContractorsDashboard.js';
import MyContractors from './MyContractors.js';
import MyPeergroup from './MyPeergroup.js'
let days = 10;

const RadarForContractingBody = ({updateRequest}) => {    
  const { industries, currentUser } = useContext(AppContext);
  const [filteredIndustry, setFilteredIndustry] = useState(currentUser?.defaultIndustry);
  let localIndustries = industries;
  if(localIndustries.findIndex(x => x.name === 'Alle') == -1) {
      localIndustries.push({name: 'Alle'});
  }
  localIndustries = orderBy(localIndustries, 'name' );

  useEffect(() => {
    
   }, [])

  return (
    <Fragment>      
         <Row className="pt-2">
            <Col md={9} className="mb-3">
                <h5 style={{ display: 'inline' }}>Markt Radar {filteredIndustry} für Auftraggeber{' '}</h5>
                <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="text-400"
                id="RadarForContractingBodyTooltip"
                />
                <UncontrolledTooltip placement="bottom" target="RadarForContractingBodyTooltip">
                    Die wichtigsten Veränderungen in den letzen {days} Tagen. Brancheneinschränkung: {filteredIndustry}
                </UncontrolledTooltip>                                              
            </Col>
            <Col md={3} className="mb-3">
                <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    bsSize="sm"
                    className="select-month "
                    value={filteredIndustry}
                    onChange={({ target }) => setFilteredIndustry(target.value)}
                >
                    {isIterableArray(localIndustries) &&
                    localIndustries.map((industry, index) => (
                        <option key={index} value={industry.name}>
                            {capitalize(industry.name)}
                        </option>
                    ))}
                </CustomInput>
            </Col>
        </Row>    
    
    <Row >
        <Col xs={12} md={12} lg={12} className="mb-3" >
          <MyContractors filteredIndustry={filteredIndustry}></MyContractors>
        </Col>        
    </Row>    

    <Row >
        <Col xs={12} md={12} lg={12} className="mb-3" >
            <MyPeergroup filteredIndustry={filteredIndustry}></MyPeergroup>
          
        </Col>        
    </Row>
    <Row >
            <Col xs={12} md={12} lg={6} className="mb-3" >
                <MarketNewConnections days={days} filteredIndustry={filteredIndustry} updateRequest={updateRequest}></MarketNewConnections>
            </Col>                
            <Col xs={12} md={12} lg={6} className="mb-3" >
                <NewContractorsDashboard days={30} filteredIndustry={filteredIndustry} updateRequest={updateRequest}></NewContractorsDashboard>
            </Col>            
        </Row>         
         
        <Row >
            <Col className="mb-3">
                <MarketInsolvencies days={days} filteredIndustry={filteredIndustry} updateRequest={updateRequest}></MarketInsolvencies>
            </Col>
        </Row>
</Fragment>


  );
};

export default RadarForContractingBody;