import moment from 'moment';

export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};


export const checkPrefix = (id) => {
  return(
    (id && id.indexOf('urn:uuid:') > -1) ? id : 'urn:uuid:' + id
  )
}

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
  parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
  if (!moment.isMoment(startDate)) throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
  if (endDate && !moment.isMoment(endDate))
    throw new Error(`End date must be a moment object, received ${typeof startDate}`);

  return `${startDate.format('ll')} - ${endDate ? endDate.format('ll') : 'Present'} • ${startDate.from(
    endDate || moment(),
    true
  )}`;
};

export const daysPassed = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
}

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'k'
    : Math.abs(Number(number)).toFixed(fixed);
};

export const numberToT = (number, fixed = 2) => {
  return Math.abs(Number(number)) >= 1.0e3    
    ? Intl.NumberFormat().format((Math.abs(Number(number)) / 1.0e3).toFixed(fixed)).toString()
    : '';
};


export const numberFormatter2 = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  let numberOut = '...';

  if (number) numberOut = Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'k'
    : Math.abs(Number(number)).toFixed(fixed);
  return numberOut;
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) => (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  console.log('textField', textField);
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const median = values => {
  if(values.length ===0) return 0;

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const addMonths = (date, months) => {
    var m = moment(date);
    m.add(months, 'M');
  return m.toDate();
}

export const weekDayName = (offset) => {
  var days = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'];
  var now = new Date();
  return days[addDays(now, offset).getDay()];
}

export const forecastCompleteMonth = (value) => {
  let today = new Date();
  let daysThisMonth = new Date(today.getFullYear(), today.getMonth()+1, 0).getDate();
  return (value / today.getDate())*daysThisMonth;
}

export const changeIndicatorColor = (value, lessIsMore = false) => {
  let bad = 'soft-warning';
  let good = 'soft-success';

  if (value && value < 100 && !lessIsMore) {
    return bad;
  }
  else if (value && value < 100 && lessIsMore) {
    return good;
  }
  else if (value && value > 100 && !lessIsMore) {
    return good;
  }
  else if (value && value > 100 && lessIsMore) {
    return bad;
  }
  else
  {
    return 'soft-info';
  }
}

export const changeIndicatorColor2 = (value, lessIsMore = false) => {
  let bad = 'soft-warning';
  let good = 'soft-success';

  if (value && value < 1 && !lessIsMore) {
    return bad;
  }
  else if (value && value < 1 && lessIsMore) {
    return good;
  }
  else if (value && value > 1 && !lessIsMore) {
    return good;
  }
  else if (value && value > 1 && lessIsMore) {
    return bad;
  }
  else
  {
    return 'soft-info';
  }
}

export const niceDate = (dateIn, expandToday, suffixUhr) => {
  const dateWork = new Date(dateIn);
  const dateNow = new Date();
  var ms = new Date().getTime() - 86400000;
  var dateYesterday = new Date(ms);

  var time = String(dateWork.getHours()).padStart(2, '0') + ':' + String(dateWork.getMinutes()).padStart(2, '0');
  var date = String(dateWork.getDate()).padStart(2, '0') + '.' + String((dateWork.getMonth()+1)).padStart(2, '0') + '.' + dateWork.getFullYear();
  var dateToday = String(dateNow.getDate()).padStart(2, '0') + '.' + String((dateNow.getMonth()+1)).padStart(2, '0') + '.' + dateNow.getFullYear();

  if (
    dateWork.getDate() == dateYesterday.getDate() &&
    dateWork.getMonth() == dateYesterday.getMonth() &&
    dateWork.getFullYear() == dateYesterday.getFullYear())
    {
      return 'Gestern ' + (suffixUhr ? time + ' Uhr' : time);
    }
  else if (date == dateToday)
    {
      if (expandToday)
      {
        return 'Heute ' + (suffixUhr ? time + ' Uhr' : time);
      }
      else
      {
        return time;
      }
    }
  else if (!dateIn)
    {
      return '';
    }
    else
    return date;
  }

  export const shortDate = (dateIn, includeTime) => {
    const dateWork = new Date(dateIn);
      
    var time = String(dateWork.getHours()).padStart(2, '0') + ':' + String(dateWork.getMinutes()).padStart(2, '0');
    var date = String(dateWork.getDate()).padStart(2, '0') + '.' + String((dateWork.getMonth()+1)).padStart(2, '0') + '.' + dateWork.getFullYear();
  
    return(includeTime ? date + ' ' + time : date);
    }

  export const longTextStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '15em'
  }
  export const shortTextStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '5em'
  }
  export const recordsCaption = (count, type=1) => {
    if(type == 1) {
      if(count == 0) {return 'Kein Eintrag' }
      if(count == 1) {return '1 Eintrag' }
      if(count > 1) {return count + ' Einträge' }
    }
    if(type == 2) {
      if(count == 0) {return 'Keine Dateien' }
      if(count == 1) {return '1 Datei' }
      if(count > 1) {return count + ' Dateien' }
    }    
  }

  // copy to clipboard from tables
  export const copyToClipboard = (row, column) => {
    if(column.name == 'contractor') {
      navigator.clipboard.writeText(
          row[column.dataField] ?? row.contractorPublicationName
      );
    } else {
      navigator.clipboard.writeText(row[column.dataField]);
    }
  }

  export const industriesToFilter = (industries) => {
    let work = '';
    if(industries && industries.length > 0 && industries != 'Alle') {
      work = 'FILTER (';

      if(Array.isArray(industries))
      {
        industries.map((item, index) => {
          if(index > 0) { work += '|| '}
          work += `?labelGroup = "${item.name}"`;
        });
      }
      else
      {
        work += `?labelGroup = "${industries}"`;
      }
      work += ')';
    }
    return work;
  }

  export const industriesToCaption = (industries) => {
    let work = '';
    if(industries && industries.length > 0) {
      industries.map((item, index) => {
        if(index > 0) { work += ', '}
        work += item.name;
      });
    }
    else { }
    return work;
  }

  export const kdpPublicationStageToCaption = (processStage) => {
    if(processStage == 'award') { return 'Zuschlag'; }
    if(processStage == 'new') { return 'Neu'; }
  }

  export const activityTypes = [
    { text: "Energie", value: "_ENERGY"},
    { text: "Verkehr", value: "_TRAFFIC"},
    { text: "Verteidigung", value: "DEFENCE" },
    { text: "Wirtschaft und Finanzen", value: "ECONOMIC_AND_FINANCIAL_AFFAIRS"},
    { text: "Bildung und Forschung", value: "EDUCATION"},
    { text: "Umwelt und Landwirtschaft", value: "ENVIRONMENT"},
    { text: "Allgemein", value: "GENERAL_PUBLIC_SERVICES"},
    { text: "Gesundheit", value: "HEALTH"},
    { text: "Immobilien und Infrastruktur", value: "HOUSING_AND_COMMUNITY_AMENITIES"},
    { text: "Ordnung und Sicherheit", value: "PUBLIC_ORDER_AND_SAFETY"},
    { text: "Erholung, Kultur und Religion", value: "RECREATION_CULTURE_AND_RELIGION"},
    { text: "Sozialer Schutz", value: "SOCIAL_PROTECTION"}
];

export const explainStage = (value) => {  
    switch(value)
    {
    case 'award':
      return 'Zuschlag';
    case 'new':
      return 'Neu';
    }  
};


export const shortFileName = (fullFileName) => {
  let fileName = fullFileName.substring(fullFileName.lastIndexOf('/')+1);
  if(fileName.length > 40) {    
    let extension = fullFileName.substring(fullFileName.lastIndexOf('.')+1);
    return fullFileName.substring(fullFileName.lastIndexOf('/')+1, fullFileName.lastIndexOf('/')+21) + ' ... ' + fullFileName.substring(fullFileName.lastIndexOf('.')-11, fullFileName.lastIndexOf('.')) + '.' + extension;
  }
  else {
    return fileName
  }
}

export const yesNoFormatter = (value) => {
  if(value != undefined && value != null) {
    if(value) {
      return 'Ja';
    }
    else
    {
      return 'Nein';
    }
  }
  else {
    return '-';
  }
}

export function checkTimeRange(filterFrom, filterTo) {
  if (filterTo >= filterFrom) { return true;}
  if (filterTo && !filterFrom) {return true;}
  if (filterFrom && !filterTo) {return true;}
}
