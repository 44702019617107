import React, { useState, useEffect, useContext, Fragment} from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
import { Button } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';

const CurrentCompetitorList = props => {
  const { currentUser } = useContext(AppContext);
 
  const delteNotificationRequest = (params) => {    
    let profileUrl = `/v1/documents?uri=/user-profile/${currentUser.user?.replace(/.*:/, '')}.json`;
    const get = async e => {
        let request = new Request(
            profileUrl,
            {
                method: 'GET'
            });
        let response = await fetch(request);
        let currentProfile = await response.json(); 
        let n = currentProfile.notificationRequests.filter(request => request.search !== params.data.org);        
        currentProfile.notificationRequests = n;
        var requestUpdate = new Request(
            profileUrl,
            {
                method: 'PUT',
                body: JSON.stringify(currentProfile)
            });
        var responseUpdate = await fetch(requestUpdate);        
        
        props.setUpdate(new Date().toISOString());
    }
    get();
  }
  

  const renderSearch = (params) => {
    
    if(params.data.org) {
      
        // let link = (row.mainType === 'AG' ? '/auftraggeber/' : '/bieter/') + row.contractingBody.replace('urn:uuid:', '');
        let link = '/org/redirect/' + params.data.org.replace('urn:uuid:', '');
        return(<Link to={link}>{params.data.orgName}</Link>)
      
    } else {
      return (<Fragment>-</Fragment>)
    }  
  }
  
  const renderDeleteNotificationRequest = (params) => {
    return (<Button onClick={() => delteNotificationRequest(params)} variant="primary" type="submit" size="sm">Löschen</Button>)
  }    

  const [notificationsColumnDefs] = useState([
    { field: "org", headerName: 'Löschen',  cellRenderer: renderDeleteNotificationRequest, flex: 1 },        
    { field: "orgName", headerName: 'Lieferant', cellRenderer: renderSearch, flex: 3 }    
  ]);

  useEffect(() => {   
   }, [props.update, props.filteredIndustry])

  return (
    <Fragment>      
        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
          <AgGridReact
            defaultColDef={{sortable: true, filter: true, resizable: true }}
            rowData={props.cList}
            columnDefs={notificationsColumnDefs}
            overlayNoRowsTemplate={'<span>Keine ausgewählt</span>'}            
            domLayout='autoHeight'>
          </AgGridReact>
        </div>        
    </Fragment>

  );
};

export default CurrentCompetitorList;