import { getProfile } from './profile';

export async function notificationExists(user, requestType, industry, org, keyword, activityType) {    
    let found = false;    
    let profile = await getProfile(user);    
    if(profile.notificationRequests) {
      if(industry && industry != "-") {        
        // let industryR = (industry && industry !== "-") ? industry : '';
        if(requestType === 'organization') { found = (profile.notificationRequests.find((r) => r.industry === (industry) && r.search === org && r.requestType === requestType)) ? true : false; }
        if(requestType === 'keyword') { found = (profile.notificationRequests.find((r) => r.industry === (industry) && r.search === keyword && r.requestType === requestType)) ? true : false; }
        if(requestType === 'newRelationshipIndustry') { found = (profile.notificationRequests.find((r) => r.industry === (industry) && r.requestType === requestType)) ? true : false; }
        if(requestType === 'newContractorIndustry') { found = (profile.notificationRequests.find((r) => r.industry === (industry) && r.requestType === requestType)) ? true : false; }
        if(requestType === 'newPublicationStageNewIndustry') { found = (profile.notificationRequests.find((r) => r.industry === (industry) && r.requestType === requestType)) ? true : false; }
        if(requestType === 'newPublicationActivityType') { found = (profile.notificationRequests.find((r) => r.industry === (industry) && r.activityType === activityType && r.requestType === requestType)) ? true : false; }
      } else {        
        if(requestType === 'organization') { found = (profile.notificationRequests.find((r) => r.search === org && r.requestType === requestType)) ? true : false; }
        if(requestType === 'keyword') { found = (profile.notificationRequests.find((r) => r.search === keyword && r.requestType === requestType)) ? true : false; }
        if(requestType === 'newPublicationActivityType') { found = (profile.notificationRequests.find((r) => r.activityType === activityType && r.requestType === requestType && r.industry === '')) ? true : false; }
      }      
    }    
    return found;
  }