import React, { useState, useEffect, useContext, Fragment} from 'react';
import { Button, Card, CardBody, Collapse, UncontrolledTooltip} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SparqlHook from '../../SparqlHook.js';
import AppContext from '../../context/Context';
import { getProfile } from '../../helpers/profile';
import AddContractingBody from './AddContractingBody.js';
import CurrentCbList from './CurrentCbList';
import ContractingBodiesAwards from './ContractingBodiesAwards.js';
import ContractingBodiesPublications from './ContractingBodiesPublications';
import { faCaretDown, faCaretUp  } from '@fortawesome/free-solid-svg-icons'
const ContractingBodies = props => {
  const { runQuery } = SparqlHook();
  const [isOpen, setIsOpen] = useState(false);
  const [update, setUpdate] = useState(new Date().toISOString());
  const toggle = () => setIsOpen(!isOpen);  
  const [effectiveFrom, setEffectiveFrom] = useState(new Date().toISOString());  
  const { currentUser } = useContext(AppContext);
  const [data, setData] = useState();

  async function loadList() {
      let p = await getProfile(currentUser);
      let orgs;
      if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') {
        orgs = p?.notificationRequests?.filter((n) => n.requestType === 'organization' && n.industry === props.filteredIndustry);
      } else {      
        orgs = p?.notificationRequests?.filter((n) => n.requestType === 'organization');
      }

      let orgQuery = '';  
          
      for (var i = 0; i < orgs?.length; i++) {            
        orgQuery += `?org = <${orgs[i].search.toLowerCase()}> ${(orgs.length > 1 && i < orgs.length - 1) ? ' || ' : ''}`;    
      }
  
      let queryString = `
      PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
      PREFIX core-type: <https://deepick.eu/schema/sql-import/v1/type#>
        
      SELECT  ?org ?orgName
          {          
            ?org a core-type:organization.
            ?org core:name ?orgName.
            ?org core:mainType "AG".
                      
          FILTER(
            ${orgQuery}      
          )          
         }
      `;
      
      runQuery({ query: queryString})        
      .then(result => {
        // console.log('result', result);
        setData(result);
      });
      

  }

  useEffect(() => {
    loadList();
   }, [props.filteredIndustry, update])

  return (
    <Fragment>
      <Card>        
        <FalconCardHeader
          className="pb-0"
          title={
            <Fragment>
              <span style={{ display: 'inline' }}>Meine beobachteten Kunden&nbsp;</span>
              <FontAwesomeIcon
              icon={['far', 'question-circle']}
              transform="shrink-1"
              className="text-400"
              id="ContractingBodiesTooltip"
              style={{ display: 'inline' , verticalAlign: 'top'}}
              />
              <UncontrolledTooltip placement="bottom" target="ContractingBodiesTooltip">
              Sie werden über alle Aktivitäten dieser Auftraggeber in dieser Branche ({props.filteredIndustry}) am Laufenden gehalten.
              </UncontrolledTooltip>
            </Fragment>
            }
            light={false}
            titleTag="h6"
        >                          
        </FalconCardHeader>
        <CardBody>                            
          <Button onClick={toggle} style={{ marginBottom: '1rem', width: '9rem' }} className="glyphicon glyphicon-asteris" size="sm">Auswählen&nbsp;
            <Fragment>
              { 
              (isOpen)
              ?
              <FontAwesomeIcon icon={faCaretUp}/>
              :
              <FontAwesomeIcon icon={faCaretDown}/>
            }
          </Fragment>        
          </Button>
          <Collapse isOpen={isOpen}>
            <AddContractingBody setUpdate={setUpdate} update={update} currentUser={currentUser} filteredIndustry={props.filteredIndustry} effectiveFrom={effectiveFrom}></AddContractingBody>            
            <CurrentCbList cbList={data} setUpdate={setUpdate} update={update} filteredIndustry={props.filteredIndustry}></CurrentCbList>
          </Collapse>          
          <ContractingBodiesAwards cbList={data} filteredIndustry={props.filteredIndustry} update={update}></ContractingBodiesAwards>
          <ContractingBodiesPublications cbList={data} filteredIndustry={props.filteredIndustry} update={update}></ContractingBodiesPublications>
        </CardBody>
      </Card>      
    </Fragment>
  );
};

export default ContractingBodies;