import React, { Fragment, useEffect, useState, useContext } from 'react';
import { DropdownItem, DropdownMenu, Form, Input, Dropdown, DropdownToggle, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { isIterableArray, niceDate } from '../../helpers/utils';
import Flex from '../common/Flex';
import AppContext from '../../context/Context';

const categoryToRoute = (category) => {
  if(category == 'contractingBody') { return 'auftraggeber' }
  if(category == 'contractor') { return 'bieter' }
}

const SearchBox = ({ autoCompleteItem }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState([]);
  const { organizationList, setAutoCompleteHistory } = useContext(AppContext);
  
  const recentlyBrowsedItems = autoCompleteItem.filter(item => item.category === 'recentlyBrowsedItem');
  const suggestedFilters = autoCompleteItem.filter(item => item.category === 'suggestedFilter');
  const fuseJsOptions = {
    includeScore: true,
    ignoreLocation: true,
    keys: ['name', 'text', 'breadCrumbTexts']
  };

  let history = useHistory();

  const MediaSearchContent = ({ item }) => {
    return (
      <DropdownItem className="px-card py-2" tag={Link} to={'/'.concat(categoryToRoute(item.category)).concat('/').concat(item.id.replace('urn:uuid:', ''))}  onClick={() => addSearchHistory(item)}>
        <Media className="align-item-center">
          <Media body className="ml-2">
            <h6 className="mb-0">{item.name.substring(0, 40)}</h6>
            <p className="fs--2 mb-0">{niceDate(item.first)} - {niceDate(item.last)} ({item.count} x)</p>
          </Media>
        </Media>
      </DropdownItem>
    );
  };
  
  const addSearchHistory = (item) => {
    let newSet = autoCompleteItem;
    let orgType = '';
    let baseUrl = '';
    if(item.category === 'contractingBody') { 
      orgType = 'AG '; 
      baseUrl = '/auftraggeber/'
    }
    if(item.category === 'contractor') { 
      orgType = 'Bieter '; 
      baseUrl = '/bieter/'
    }
    let newItem = {
      url: `${baseUrl}${item.id.replace('urn:uuid:', '')}`,
      breadCrumbTexts: [orgType, item.name],
      category: 'recentlyBrowsedItem'
    }
    newSet.push(newItem);
    if(newSet.length > 5) { newSet.shift();}
    setAutoCompleteHistory(newSet);
  }
  
  let searchResult = new Fuse(organizationList, fuseJsOptions).search(searchInputValue).map(item => item.item);

  const foundContractingBodies = resultItem.filter(item => item.category === 'contractingBody');
  const foundContractors = resultItem.filter(item => item.category === 'contractor');

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (searchInputValue) {
      setResultItem(searchResult);
      isIterableArray(searchResult) ? setDropdownOpen(true) : setDropdownOpen(false);
    } else {
      setResultItem([]);
    }

    // eslint-disable-next-line
  }, [searchInputValue]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="search-box">
      <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        <Form onSubmit={e => { e.preventDefault(); }}>
          <Input
            type="search"
            placeholder="Organisation suchen ..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onClick={() => setDropdownOpen(false)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                history.push(`/org/search/${searchInputValue}`);
              }
            }}
          />
          <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
        </Form>
        {searchInputValue && (
          <button className="close" onClick={() => setSearchInputValue('')}>
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
          {isIterableArray(recentlyBrowsedItems) && (
            <>
              <DropdownItem className="px-card pt-0 pb-2" header>
                Letzte Einträge{' '}
              </DropdownItem>
              {recentlyBrowsedItems.map((item, index) => (
                <DropdownItem
                  tag={Link}
                  to={item.url}
                  className="fs--1 px-card py-1 hover-primary text-base"
                  key={index}
                >
                  <Flex align="center">
                    <FontAwesomeIcon icon="circle" className="mr-2 text-300 fs--2" />
                    <div className="font-weight-normal">
                      {item.breadCrumbTexts.map((breadCrumbText, index) => {
                        return (
                          <Fragment key={index}>
                            {breadCrumbText}
                            {item.breadCrumbTexts.length - 1 > index && (
                              <FontAwesomeIcon
                                icon="chevron-right"
                                className="mx-1 text-500 fs--2"
                                transform="shrink 2"
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </Flex>
                </DropdownItem>
              ))}
              {(isIterableArray(suggestedFilters) ||
                isIterableArray(foundContractingBodies) ||
                isIterableArray(foundContractors)) && <hr className="border-200" />}
            </>
          )}
          {/*
          {isIterableArray(suggestedFilters) && (
            <>
              <DropdownItem className="px-card pt-0 pb-2" header>
                Mögliche Filter
              </DropdownItem>
              {suggestedFilters.map((item, index) => (
                <DropdownItem tag={Link} to={item.url} className=" px-card py-1 fs-0" key={index}>
                  <Flex align="center">
                    <Badge color={`soft-${item.type}`} className="font-weight-medium text-decoration-none mr-2">
                      {item.key}:{' '}
                    </Badge>
                    <div className="flex-1 fs--1">{item.text}</div>
                  </Flex>
                </DropdownItem>
              ))}
              {(isIterableArray(foundContractingBodies) || isIterableArray(foundContractors)) && (
                <hr className="border-200" />
              )}
            </>
          )}
          */}
          {isIterableArray(foundContractingBodies) && (
            <>
              <DropdownItem className="px-card pt-0 pb-2" header>
                Auftraggeber
              </DropdownItem>
              {foundContractingBodies.slice(0, 10).map((item, index) => (
                <MediaSearchContent item={item} key={index} />
              ))}
              {isIterableArray(foundContractors) && <hr className="border-200" />}
            </>
          )}

          {isIterableArray(foundContractors) && (
            <>
              <DropdownItem className="px-card pt-0 pb-2" header>
                Bieter
              </DropdownItem>
              {foundContractors.slice(0, 10).map((item, index) => (
                <MediaSearchContent item={item} key={index} />
              ))}
            </>
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchBox;
