import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Row, Col, UncontrolledTooltip, CustomInput} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../context/Context';
import {
    isIterableArray, capitalize
} from '../../helpers/utils';
import orderBy from "lodash/orderBy";
import Radar from './../radar/Radar.js'

const MarketReport = ({ updateRequest }) => {
    const { industries, currentUser } = useContext(AppContext);
    const [filteredIndustry, setFilteredIndustry] = useState(currentUser?.defaultIndustry);
    
    let localIndustries = industries;
    if(localIndustries.findIndex(x => x.name === 'Alle') == -1) {
        localIndustries.push({name: 'Alle'});
    }
    localIndustries = orderBy(localIndustries, 'name' );
    let days = 10;

    useEffect(() => {             
    }, [filteredIndustry, updateRequest])
  return (   
    <Fragment>             
        <Row>
            <Col xs={12} md={12} lg={12} className="mb-3" >
                <Radar></Radar>
            </Col>
        </Row>        
    </Fragment>
  );
};

export default MarketReport;