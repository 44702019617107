import React, { Fragment } from 'react';
import { longTextStyle, shortTextStyle, recordsCaption, copyToClipboard, niceDate, industriesToCaption, industriesToFilter, kdpPublicationStageToCaption, numberFormatter, numberToT } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import { OrganizationLogo } from "../common/OrganizationLogo";

const renderContractor = (params) => {
  if (params.data.contractor) {
    let additionalNote = '';
    if(params.data?.numberOfContractor > 1) { additionalNote = `(+${params.data.numberOfContractor-1})` }
 
    let link = '/bieter/' + params.data.contractor.replace('urn:uuid:', '');
    if (params.data.contractorName != null) {
      return <Link to={link}>{params.data.contractorName}<sup>{' ' + additionalNote}</sup></Link>;
    }
  } else {
    return <Fragment>{params.data.contractorPublicationName}</Fragment>;
  }
}

const renderContractingBody = (params) => {
  if (params.data.contractingBody) {    
    let link = '/auftraggeber/' + params.data.contractingBody.replace('urn:uuid:', '');
    if (params.data.contractingBodyName != null) {
      return <Link to={link}>{params.data.contractingBodyName}</Link>;
    }
  } else {
    return <Fragment>{params.data.contractingBodyNamePublication}</Fragment>;
  }
}

const renderLogoContractor = (params) => {    
  if(params.data.contractor) {
    return <OrganizationLogo iri={params.data.contractor} editorMode={false} size={'35px'} frameSize={'40px'} />
  } else {
    return <Fragment></Fragment>
  }
}

const renderLogoContractingBody = (params) => {
  if(params.data.contractingBody) {
    return <OrganizationLogo iri={params.data.contractingBody} editorMode={false} size={'35px'} frameSize={'40px'} />
  } else {
    return <Fragment></Fragment>
  }  
}

const formatNiceDate = (params) => {
  return niceDate(params.value, 1);
};

const formatTotalValue = (params) => {
  return numberToT(params.value, 0);
}

const formatStage = (params) => {
  return kdpPublicationStageToCaption(params.value)
}

const renderTitle = (params) => {
  let target = '/publication?id=' + encodeURIComponent(params.data.publication.substr(params.data.publication.indexOf('/kerndaten')));
  return (
    <Link to={target}>{params.data.title}</Link>
  );
}

const formatNumberOfFiles = (params) => {  
    if(params.value && params.value > 0) {
      return (
        params.value
      )
    }
    else {
      if(params.data.processStage == 'new') { return ''}
      if(params.data.processStage == 'award') { return '-'}
    }
  }

const allColumnsAGGrid = [  
    { colId: "contractorLogoContractor", field: "contractor", headerName: '', cellRenderer: renderLogoContractor, flex: 2 },
    { colId: "contractingBodyLogo", field: "contractingBody", headerName: '', cellRenderer: renderLogoContractingBody, flex: 1 },
    { colId: "contractor", field: "contractorPublicationName", headerName: 'Bieter', cellRenderer: renderContractor, editable: true, flex: 4 },    
    { colId: "contractingBody", field: "contractingBodyNamePublication", headerName: 'AG', cellRenderer: renderContractingBody, editable: true, flex: 4 },
    { colId: "kdqLastmod", field: "kdqLastmod", headerName: 'Zuletzt', valueFormatter: formatNiceDate, flex: 1.5 },
    { colId: "downloadDate", field: "downloaded", headerName: 'Neu seit', valueFormatter: formatNiceDate, flex: 1.5 },
    { colId: "industry", field: "labelGroup", headerName: 'Branche', flex: 1 },
    { colId: "label", field: "labelName", headerName: 'Leistung', flex: 2 },
    { colId: "stage", field: "processStage", headerName: 'Stufe', valueFormatter: formatStage, flex: 1 },
    { colId: "title", field: "title", headerName: 'Titel', cellRenderer: renderTitle, editable: true, flex: 4 },
    { colId: "totalValue", field: "totalValue", headerName: 'TEUR', type: 'numericColumn', valueFormatter: formatTotalValue, flex: 1 },
    { colId: "numberOfFiles", field: "numberOfFiles", headerName: 'Dateien', type: 'numericColumn', valueFormatter: formatNumberOfFiles, flex: 1 },

]

const allColumns = [
      {
        name: 'kdqLastmod',
        dataField: 'kdqLastmod',
        text: 'Zuletzt',
        style: longTextStyle,
        sort: true,
        formatter: (cell, row) => { return niceDate(cell)},
        headerStyle: (colum, colIndex) => { return { width: '140px' };}
    },
    {
        name: 'downloadDate',
        dataField: 'downloaded',
        text: 'Neu seit',
        style: longTextStyle,
        sort: true,
        formatter: (cell, row) => { return niceDate(cell)},
        headerStyle: (colum, colIndex) => { return { width: '140px' };}
    },
    {
      name: 'dateDispatchNotice',
      dataField: 'dateDispatchNotice',
      text: 'Abgefertigt am',
      style: longTextStyle,
      sort: true,
      formatter: (cell, row) => { return niceDate(cell)},
      headerStyle: (colum, colIndex) => { return { width: '140px' };}
    },
  
    {
        name: 'industry',
        dataField: 'labelGroup',
        text: 'Branche',
        style: longTextStyle,
        sort: true,
        headerStyle: (colum, colIndex) => { return { width: '120px' }; }
    },
    {
      name: 'label',
      dataField: 'labelName',
      text: 'Leistung',
      style: longTextStyle,
      sort: true,
      headerStyle: (colum, colIndex) => { return { width: '120px' }; }
  },    
    {
        name: 'stage',
        dataField: 'processStage',
        text: 'Stufe',
        style: longTextStyle,
        sort: true,
        formatter: (cellContent, row) => { return ( kdpPublicationStageToCaption(row.processStage))},
        headerStyle: (colum, colIndex) => { return { width: '120px' };}
    },
    {
        name: 'contractingBody',
        dataField: 'contractingBodyName',
        text: 'Auftraggeber',
        style: shortTextStyle,
        sort: true,
        events: { onClick: (e, column, columnIndex, row, rowIndex) => { copyToClipboard(row, column)}},
        formatter: (cellContent, row) => {
          if(row.numberOfContractingBodies > 1) { additionalNote = `(+${row.numberOfContractingBodies-1})`}
              let additionalNote = '';
            if(row.contractingBody) {
              let link = '/auftraggeber/' + row.contractingBody.replace('urn:uuid:', '');              
              return (
                  <Link to={link}>{row.contractingBodyName}<sup>{' ' + additionalNote}</sup></Link>
              );
            }
            else
          {
            return (
              <Fragment>{row.contractingBodyNamePublication}<sup>{' ' + additionalNote}</sup></Fragment>
            )
          }
            
        }
    },
    {
        name: 'contractor',
        dataField: 'contractorName',
        text: 'Bieter',
        style: shortTextStyle,
        sort: true,
        events: { onClick: (e, column, columnIndex, row, rowIndex) => { copyToClipboard(row, column)}},
        formatter: (cellContent, row) => {
            let additionalNote = '';
            if(row.numberOfContractor > 1) { additionalNote = `(+${row.numberOfContractor-1})` }
            if (row.contractor) {
                let link = '/bieter/' + row.contractor.replace('urn:uuid:', '');
                if(row.contractorName != null) {
                return (
                    <Link to={link}>{row.contractorName}<sup>{' ' + additionalNote}</sup></Link>
                );}
            }
            else {
                return (
                    <Fragment>{row.contractorPublicationName}<sup>{' ' + additionalNote}</sup></Fragment>
                );}
        }
    },
    {
        name: 'title',
        dataField: 'title',
        text: 'Titel',
        style: shortTextStyle,
        sort: true,
        events: { onClick: (e, column, columnIndex, row, rowIndex) => { copyToClipboard(row, column)}},
        formatter: (cellContent, row) => {
          let target = '/publication?id=' + encodeURIComponent(row.publication.substr(row.publication.indexOf('/kerndaten')));
          return (
            <Link to={target}>{row.title}</Link>
          );
        }
    },
    {
      name: 'totalValue',
      dataField: 'totalValue',
      text: 'TEUR',
      sort: true,
      // events: { onClick: (e, column, columnIndex, row, rowIndex) => { copyToClipboard(row, column)}},
      align: 'right',
      headerAlign: 'right',
      headerStyle: (colum, colIndex) => {
        return { headerAlign: 'right' };
      },
      sortValue: (cell, row) => (row.totalValue ? parseFloat(row.totalValue) : 0),
      formatter: (cellContent, row) => {
        if (row.totalValue) {
          return (
              <Fragment>{numberToT(row.totalValue, 0)}</Fragment>
          );
        }
      }
    },
    {
      name: 'numberOfFiles',
      dataField: 'numberOfFiles',
      text: 'Dateien',
      style: longTextStyle,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '120px' };
      },
      align: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      formatter: (cellContent, row) => {
        if(row.numberOfFiles > 0) {
          return (
            row.numberOfFiles
          )
        }
        else {
          if(row.processStage == 'new') { return ''}
          if(row.processStage == 'award') { return '-'}
        }
      }
    },
    {
      name: 'numberOfRelated',
      dataField: 'numberOfRelated',
      text: 'Weitere',
      style: longTextStyle,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '120px' };
      },
      align: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      formatter: (cellContent, row) => {
        if(row.numberOfRelated > 1) {
          return (
            row.numberOfRelated - 1
          )
        }
        if(row.numberOfRelated == 1) {
          return (
            '-'
          )
        }
      }
    }
];

export const getColumns = (props) => {
    return allColumns.filter(o1 => props.columns.some(o2 => o1.name === o2.name));
}

export const getColumnsAGGrid = (props) => {
  let columns = [];
  props.columns.map(c => {
    let def = allColumnsAGGrid.find(defItem => defItem.colId === c.colId);
    if(def) {
      columns.push(def);  
    }
    }
  )
  // console.log('columns', columns);  

  return columns
}


