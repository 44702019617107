import React, { useContext, useState, useEffect } from 'react';
import { Badge, Card, CardBody, CardImg, CardTitle, CardLink, CardText, ListGroup, ListGroupItem, CardFooter, Col, Row } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import { OrganizationLogo } from '../common/OrganizationLogo';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

import {
  niceDate,
  numberToT  
} from '../../helpers/utils';
import BankruptcyWarning from './BankruptcyWarning.js';

const sizeIndicator = (totalValue) => {
  let numberOfstars = 0;
  let stars = [];
  if(totalValue) {
    if(totalValue > 0) { numberOfstars = 1;}
    if(totalValue > 100000) { numberOfstars = 2;}
    if(totalValue > 1000000) { numberOfstars = 3;}
    if(totalValue > 2000000) { numberOfstars = 4;}
  }
  for (let i = 0; i < numberOfstars; i++) {
    stars.push(<FontAwesomeIcon icon={faEuroSign} key={i} />);
}

  return (
    <Fragment>              
      {stars}        
    </Fragment>
  )
}

const ContractorCardNewPrint = (props) => {    

  useEffect(() => {
   
  }, [props.org.contractor, props.industry]);

  return (
    <Fragment>
    { (props.org) 
      ? 
    <Fragment>          
      <div style={{backgroundColor: 'white'}} className="ml-2 mt-2">
        <div className="ml-3 mt-4">
            <table>
                <tr>
                    <td colspan='2' width={'75%'}><h5>{props.org?.contractorName}</h5></td>
                </tr>                
                <tr>
                    
                    <td>
                        Zuletzt: {niceDate(props.org?.firstPublication)}<br></br> 
                        Bei: XYZ Org
                    </td>                    
                    <td>
                        <OrganizationLogo iri={props.org.contractor} editorMode={false} size={'120px'} frameSize={'125px'} />
                    </td>
                </tr>
                {/*
                    <tr>
                        <td colspan='2'>
                            <img src={"/images/qr-sample2.png"} style={{width: '70px', height: '70px', backgroundColor: 'white'}}></img>
                        </td>
                    </tr>
                 */}
                <tr>
                <td colSpan='2'>
                <CardLink href={'/bieter/' + props.org?.contractor?.replace('urn:uuid:', '')}>Organisation</CardLink>            
            {(props.org?.websiteDomain) ? <CardLink href={`https://www.${props.org?.websiteDomain}`}>Website</CardLink> : <Fragment></Fragment>}
</td>    
                </tr>    
            </table>
            
            
        </div>
        
      
        
      
      
   
  </div>
  </Fragment>

    :
    <Fragment>empty</Fragment>
  }
  </Fragment>
  );
};

export default ContractorCardNewPrint;
