import { useContext } from 'react';
import AppContext from './context/Context';

// https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
const SparqlHook = () => {
  const { isLoading, setIsLoading } = useContext(AppContext);

  async function runQuery({ query, collection, bindings }) {
    // console.log(new Date().toUTCString(), 'runQuery', query);
    const formData = new URLSearchParams();
    formData.append('query', query);
    if (collection) {
      formData.append('collection', collection);
    }
    for (const key in bindings) {
      formData.append('bind:' + key, bindings[key]);
    }

    setIsLoading(true);
    let response = await fetch('/v1/graphs/sparql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData.toString()
    });
    try {
      response = await response.json();
    } catch (e) {
      return e;
    }

    setIsLoading(false);
    return response.results.bindings.map(row => {
      for (let key in row) {
        let { type, datatype, value } = row[key];
        switch (datatype) {
          case 'http://www.w3.org/2001/XMLSchema#boolean':
            value = value === 'true';
            break;
          case 'http://www.w3.org/2001/XMLSchema#integer':
          case 'http://www.w3.org/2001/XMLSchema#unsignedLong':
            value = parseInt(value);
            break;
          case 'http://www.w3.org/2001/XMLSchema#decimal':
            value = parseInt(value);
            break;
          case 'http://www.w3.org/2001/XMLSchema#dateTime':
          case 'http://www.w3.org/2001/XMLSchema#date':
            break; /* date and dateTime will be left as strings */
          case undefined:
            break; /* strings */
          default:
            console.log('Warning, unmapped data type', datatype, 'in sparql response');
        }
        row[key] = value;
      }

      return row;
    });
  }

  return {
    runQuery
  };
};

export default SparqlHook;
