import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownComponents from './NavbarDropdownComponents';
import {
  editRoutes,
  chatRoutes,
  homeRoutes,
    trendRoutes,
  brancheVergabeRoutes,
  widgetsRoutes,
  contractorRoutes
} from '../../routes';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints, getPageName } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav, currentUser } = useContext(AppContext);

  const components = [brancheVergabeRoutes];

  const pages = [trendRoutes, contractorRoutes, widgetsRoutes, chatRoutes];

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };
  const isLanding = getPageName('landing');
  return (
    <>
      <NavbarDropdown title={homeRoutes.name} items={homeRoutes.children} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdown title={trendRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdown
        title={editRoutes.name}
        items={editRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      {isTopNav && !isLanding && (
        <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/documentation">
            Dokumentation
          </NavLink>
        </NavItem>
      )}
      {isLanding && (
        <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/changelog">
            Changelog
          </NavLink>
        </NavItem>

      )}

    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
