import React, { useContext } from 'react';
import { Nav, NavItem } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';
import AppContext from '../../context/Context';

const TopNavRightSideNavItem = () => {
  const { isTopNav, isCombo } = useContext(AppContext);
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <NavItem>
        <SettingsAnimatedIcon />
      </NavItem>
      {/*  <NotificationDropdown /> */}

      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
