import React, { useState, useEffect, useContext, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Collapse, UncontrolledTooltip} from 'reactstrap';
import moment from "moment";
import SparqlHook from '../../SparqlHook.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../context/Context.js';
import { AgGridReact } from 'ag-grid-react';
import { niceDate, numberToT } from '../../helpers/utils.js';
import { getProfile } from '../../helpers/profile.js';
import { tableOverlays } from '../../config.js';
import { OrganizationLogo } from "../common/OrganizationLogo.js";
import RenderContractorType from "./RenderContractorType.js";

const getNiceDate = (params) => {
    return niceDate(params.value);
  };


const CompetitorsAwards = props => {
    const { runQuery } = SparqlHook();
    const [data, setData] = useState();  
    const { currentUser } = useContext(AppContext);
    const [notificationsSet, setNotificationsSet] = useState(false);

    const renderLogoContractor = (params) => {    
      if(params.data.contractor) {
        return <OrganizationLogo iri={params.data.contractor} editorMode={false} size={'35px'} frameSize={'40px'} />
      } else {
        return <Fragment></Fragment>
      }
    }

    const renderContractor = (params) => {      
      if (params.data.contractorName) {
        let additionalNote = '';
        if(params.data?.numberOfContractor > 1) { additionalNote = `(+${params.data.numberOfContractor-1})` }
     
        let link = '/bieter/' + params.data.contractor.replace('urn:uuid:', '');        
        return <Link to={link}>{params.data.contractorName}<sup>{' ' + additionalNote}</sup></Link>;
        
      } else {
        return <Fragment>{params.data.contractorPublicationName}</Fragment>;
      }
    }
    
    const renderLogoContractingBody = (params) => {
      if(params.data.contractingBody) {
        return <OrganizationLogo iri={params.data.contractingBody} editorMode={false} size={'35px'} frameSize={'40px'} />
      } else {
        return <Fragment></Fragment>
      }  
    }
    
    const renderContractorType = (params) => {
      return <RenderContractorType params={params}/>
    }

    const renderTitle = (params) => {
      let target = '/publication?id=' + encodeURIComponent(params.data.publication.substr(params.data.publication.indexOf('/kerndaten')));
      return (
        <Link to={target}>{params.data.title}</Link>
      );
    }
    
    const formatTotalValue = (params) => {
      return numberToT(params.value, 0);
    }

    const [columnDefs] = useState([
        { field: "downloaded", headerName: 'Datum',  valueFormatter: getNiceDate, flex: 2 },
        { colId: "contractingBodyLogo", field: "contractingBody", headerName: '', cellRenderer: renderLogoContractingBody, flex: 1 },
        { field: "contractingBodyName", headerName: 'Auftraggeber', flex: 3 },
        { colId: "contractorLogoContractor", field: "contractor", headerName: '', cellRenderer: renderLogoContractor, flex: 1 },
        { field: "contractor", headerName: 'Lieferant', cellRenderer: renderContractor, flex: 3 },
        { field: "title", headerName: 'Titel', cellRenderer: renderTitle, flex: 2 },
        { colId: "totalValue", field: "totalValue", headerName: 'TEUR', type: 'numericColumn', valueFormatter: formatTotalValue, flex: 1 },
        { field: "minDownloadedCb", headerName: '', cellRenderer: renderContractorType, flex: 1 }
      ]);

    async function loadNews() {
        setNotificationsSet(false);
        let p = await getProfile(currentUser);
        let from = moment(new Date()).add(-30, 'd');
        let filterFrom = 'FILTER (?downloaded >= "' + new Date(from).toISOString() + '"^^xsd:dateTime)';      
        let orgQuery = '';
        let orgs;
        if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') {
          orgs = p?.notificationRequests?.filter((n) => n.requestType === 'organization' && n.industry === props.filteredIndustry);
        } else {
          orgs = p?.notificationRequests?.filter((n) => n.requestType === 'organization');
        }
        
        for (var i = 0; i < orgs?.length; i++) {            
            orgQuery += `?contractor = <${orgs[i].search.toLowerCase()}> ${(orgs.length > 1 && i < orgs.length - 1) ? ' || ' : ''}`;
            setNotificationsSet(true);
          }
      
          let queryString = `
          PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
          PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
          PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
          PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
          PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
      
          SELECT 
              ?publication
              ?cpvMain
              ?labelGroup 
              ?labelName
              ?processStage 
              ?contractingBody 
              ?contractingBodyName 
              ?contractingBodyNamePublication
              ?title (COUNT(DISTINCT ?contractor) AS ?numberOfContractor) 
              (COUNT(DISTINCT ?contractingBody) AS ?numberOfContractingBody) 
              
              ?contractor 
              ?contractorName 
              ?contractorPublicationName 
              ?downloaded
              ?kdqLastmod
              ?shortDescription
              ?totalValue
              
              (COUNT(DISTINCT ?resolveResultsRelated) AS ?numberOfRelated)
              (MIN(?downloadedCb) AS ?minDownloadedCb)
      WHERE
              {
                ?publication at-kdvo-raw:downloaded ?downloaded.
                ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.
                ?publication at-kdvo-raw:processStage ?processStage.
                ?publication at-kdvo-raw:title ?title.
                ?publication at-kdvo-raw:contractingBody ?contractingBodyPublication.
                ?contractingBodyPublication at-kdvo-raw:officialName ?contractingBodyNamePublication.
                ?resolveResult kg:publication ?publication.
      
                OPTIONAL {
                  ?publication at-kdvo-raw:shortDescription ?shortDescription.        
                }
                          
                OPTIONAL {
                  ?publication at-kdvo-raw:totalValue ?totalValue.
                }
            
                
                  ?resolveResult kg:contractingBody ?contractingBody.
                  ?contractingBody core:name ?contractingBodyName.
                
        
                OPTIONAL{
                  ?publication at-kdvo-raw:contractor ?contractorPublication.
                  ?contractorPublication at-kdvo-raw:officialName ?contractorPublicationName.
                }
      
                OPTIONAL{
                  ?publication at-kdvo-raw:cpvMain ?cpvMain.
                }
      
                OPTIONAL{
                  ?resolveResult kg:label ?label.
                  ?label core:name ?labelName.
                  ?label core:group ?labelGroup.
                }
      
                
                  ?resolveResult kg:contractor ?contractor.
                  ?contractor core:name ?contractorName.          
                
      
                OPTIONAL {
                  ?resolveResult kg:additionalContact ?additionalContact.
                }
      
                OPTIONAL {
                  ?resolveResult kg:tenderingProcedure ?tenderingProcedure.
                  ?resolveResultsRelated kg:tenderingProcedure ?tenderingProcedure.
                }
      
                OPTIONAL {
                  ?resolveResultCbC kg:publication ?publicationCb.                  
                  ?resolveResultCbC kg:contractingBody ?contractingBody.
                  ?resolveResultCbC kg:contractor ?contractor.                        
                  ?publicationCb at-kdvo-raw:downloaded ?downloadedCb.                  
                }

              FILTER(
                      ${orgQuery}
              )        

              
              ${(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') ? `FILTER (?labelGroup = "${props.filteredIndustry}")` : ''}
              ${filterFrom}
              FILTER (?processStage = "award")
              
            }
          GROUP BY ?publication
          ORDER BY DESC(?downloaded)
          LIMIT 50
          `;
          // console.log('orgQuery', queryString);
          runQuery({ query: queryString})        
          .then(result => setData(result));
    }


    useEffect(() => {
        loadNews();
    }, [props.update, props.filteredIndustry])

    return (
    <Fragment>
        <br></br>
        <h6 style={{ display: 'inline' }}>Zuschläge (30 Tage)&nbsp;</h6>
                <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="text-400"
                id="CompetitorsAwardsTooltip"
                style={{ display: 'inline' , verticalAlign: 'top'}}
                />
                <UncontrolledTooltip placement="bottom" target="CompetitorsAwardsTooltip">
                Zuschläge an die ausgewählten Lieferanten in den letzten 30 Tagen. 50 neueste werden angezeigt.
                </UncontrolledTooltip>
        <br></br>
        {
        (props?.cList && props.cList.length > 0)
        ?
        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
            <AgGridReact
              defaultColDef={{sortable: true, filter: true, resizable: true }}
              rowData={data}
              columnDefs={columnDefs}            
              overlayNoRowsTemplate={tableOverlays.overlayNoRowsTemplate}
              overlayLoadingTemplate={tableOverlays.overlayLoadingTemplate}
              domLayout='autoHeight'>
            </AgGridReact>
        </div>
        :
        <Fragment>
        { (props.cList?.length == 0) ? <span>Kein Mitbewerber oder Partner ausgewählt</span> : <span>Einstellungen werden geladen ...</span> }            
          <br></br>
        </Fragment>        
        }        
    </Fragment>
    );
};

export default CompetitorsAwards;