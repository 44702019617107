import React, { useContext, useState, useEffect, useRef } from 'react';
import { Fragment } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../context/Context';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Publications from '../publication/Publications';

const MarketNewPublications = (props) => {
  const { isDark } = useContext(AppContext);
    
  const [appliedFilter, setAppliedFilter] = useState({
    industries: [{ name: props.filteredIndustry }],
    initialized: true,
    searchIssued: new Date()
  });

  useEffect(() => {
    setAppliedFilter(
      {        
        // downloadFrom: new Date(new Date().setHours(0,0,0,0)),
        // downloadTo: new Date(new Date().setHours(0,0,0,0)),
        // kdqLastmodFrom: undefined,
        // kdqLastmodTo: undefined,
        industries: [{ name: props.filteredIndustry }],
        initialized: true,
        searchIssued: new Date()
      }
    );
  }, [props.filteredIndustry, props.updateRequest])

  return (    
    <Fragment>      
          <Card>
            <FalconCardHeader
              className="pb-0"
              title={
              <Fragment>                  
                  <span style={{ display: 'inline' }}>Neue Veröffentlichungen&nbsp;</span>
                  <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="text-400"
                  id="MarketNewPublicationsTooltip"
                  style={{ display: 'inline' , verticalAlign: 'top'}}
                  />
                  <UncontrolledTooltip placement="bottom" target="MarketNewPublicationsTooltip">
                  Die neuesten 30 Veröffentlichungen.
                  </UncontrolledTooltip>
              </Fragment>
              }
              light={false}
              titleTag="h6"x
            >                          
            </FalconCardHeader>            
            
                    <CardBody>
                    <Publications
                      title="Neue Veröffentlichungen"
                      toolTip="Veröffentlichungen passend zur Suche."
                      columns={[
                        { colId: 'downloadDate' },                        
                        { colId: 'contractingBodyLogo' },
                        { colId: 'contractingBody' },                    
                        { colId: 'stage' },
                        { colId: 'label' },
                        { colId: 'title' },
                        { colId: 'contractor' },
                        { colId: 'totalValue' },
                        { colId: 'numberOfFiles' }
                      ]}
                      filter={appliedFilter}
                      records={30}
                    />
                    </CardBody>                
            
            
            
          </Card>          
    </Fragment>
  );
};

export default MarketNewPublications;