import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { Link } from 'react-router-dom';
import { niceDate } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";

import SparqlHook from '../../SparqlHook.js';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Fragment } from 'react';

import { tableOverlays } from '../../config';
import { OrganizationLogo } from "../common/OrganizationLogo";

let limit = 200;

const NewContractorsDashboard = (props) => {
  const [rowData, setRowData] = useState();

  const gridRef = useRef(); 
  const { runQuery } = SparqlHook();

  const renderContractor = (params) => {
    if(params.value) {
      let link = '/bieter/' + params.value.replace('urn:uuid:', '');
      return <Link to={link}>{params.data.contractorName}</Link>
    } else {
      return <Fragment></Fragment>
    }      
  }

  const renderLogo = (params) => {    
      return <OrganizationLogo iri={params.data.contractor} editorMode={false} size={'35px'} frameSize={'40px'} />
  }


  const getNiceDate = (params) => {
    return niceDate(params.value);
  };


  const [columns] = useState([
    { field: "contractor", headerName: '', cellRenderer: renderLogo, flex: 2 },
    { field: "contractor", headerName: 'Bieter', cellRenderer: renderContractor, flex: 8 },
    { field: "firstPublication", headerName: 'Erstmals', valueFormatter: getNiceDate, flex: 2 },              
  ]);

  function loadData() {
      let industryFilter = '';      
      if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') { industryFilter = `FILTER (?labelGroup = "${props.filteredIndustry}")`}
      
      let from = moment(new Date()).add(props.days * -1, 'd');      

      let queryString = `
      PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
      PREFIX core-type: <https://deepick.eu/schema/sql-import/v1/type#>
      PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
      PREFIX kg-type: <https://deepick.eu/schema/kg/v1/type#>
      PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      PREFIX kdRaw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
      PREFIX kdRaw-type: <https://deepick.eu/schema/at-kdvo-raw/v1/type#>
      PREFIX at-kdvo-raw-type: <https://deepick.eu/schema/at-kdvo-raw/v1/type#>
      PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
      PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

      SELECT 
        ?contractor ?contractorName (COUNT(?publication) AS ?countPublication) (min(?kdqLastmod) AS ?firstPublication)
      {
        ## ?resolveResult kg:additionalContact ?organization.
        ?resolveResult kg:contractor ?contractor.
        ?resolveResult kg:publication ?publication.
                
        ?contractor a core-type:organization.
        ?contractor core:name ?contractorName.
        ?contractor core:mainType ?mainType.
        
        ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.
        ?publication at-kdvo-raw:downloaded ?downloaded.

        
        OPTIONAL {
          ?publication at-kdvo-raw:cpvMain ?cpvMain.
          ?label2cpvId core:cpv ?cpvMain.
          ?label2cpvId core:idLabel ?labelId.
          ?labelId core:name ?labelName.
          ?labelId core:group ?labelGroup.  
        }
                  
        ${industryFilter}
        FILTER (?mainType = 'AN')
        
      }
     
        GROUP BY ?contractor
        HAVING (
          xsd:dateTime(?firstPublication) > '${new Date(from).toISOString()}'^^xsd:dateTime
          )
        ORDER BY DESC(?firstPublication)
    
      LIMIT ${limit}
      ` 
      runQuery({ query: queryString})
      .then(result => {
        setRowData(result);
      });
    }

  useEffect(() => {
    loadData();
  }, [props.filteredIndustry, props.updateRequest])

  return (    
    <Fragment>      
          <Card>
            <FalconCardHeader
              className="pb-0"
              title={
              <Fragment>
                  <span style={{ display: 'inline' }}>{(props.title) ? props.title : 'Neue Lieferanten im Markt'}&nbsp;</span>
                  <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="text-400"
                  id="MarketTopContractorTooltip"
                  style={{ display: 'inline' , verticalAlign: 'top'}}
                  />
                  <UncontrolledTooltip placement="bottom" target="MarketTopContractorTooltip">
                    Lieferanten die in den letzten {props.days} Tagen erstmals - in der Branche - erwähnt wurden.
                  </UncontrolledTooltip>
              </Fragment>
              }
              light={false}
              titleTag="h6"
            >                          
            </FalconCardHeader>
            {
                <Card>                    
                    <CardBody>
                        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                            
                              <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                defaultColDef={{sortable: true, filter: true, resizable: true }}              
                                columnDefs={columns}
                                overlayNoRowsTemplate={tableOverlays.overlayNoRowsTemplate}
                                overlayLoadingTemplate={tableOverlays.overlayLoadingTemplate}                                                
                                domLayout='autoHeight'>              
                              </AgGridReact>
                            
                            
                        </div>
                    </CardBody>                
                </Card>
            }
            
          </Card>          
    </Fragment>
  );
};

export default NewContractorsDashboard;

