import React, { Fragment, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';

import { themeColors, getPosition, numberFormatter, getGrays, median, weekDayName } from '../../helpers/utils';
import LastSevenDaysItem from './LastSevenDaysItem';
import deepickColors from '../../helpers/standardColors';
import * as sparql from '../../sparql.js';

import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';

import AppContext from '../../context/Context';
import moment from 'moment';

const getOption = (data, dataBackground, isDark) => {
  const grays = getGrays(isDark);
  const arbitraryStackKey = "stack1";

  let dataNew = data.map(a => a.numberOfProcessStageNew);
  let dataAward = data.map(a => a.numberOfProcessStageAward);
  let dataInsolvency = data.map(a => a.numberOfInsolvenz);

  return {
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: grays.white,
      textStyle: { color: grays.black },
      transitionDuration: 0,
      borderColor: grays['300'],
      borderWidth: 1,
      formatter: function(params) {
        return `<strong>${params.name}:</strong> ${params.data}`;
      }
    },
    xAxis: {
      type: 'category',
      data: [weekDayName(-6), weekDayName(-5), weekDayName(-4), weekDayName(-3), weekDayName(-2), 'Gestern', 'Heute'],
      boundaryGap: false,
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    series: [
      {
        type: 'bar',
        barWidth: '5px',
        barGap: '-100%',
        itemStyle: {
          color: grays['200'],
          barBorderRadius: 10
        },
        data: dataBackground,
        animation: false,
        emphasis: { itemStyle: { color: grays['200'] } }
      },
      {
        name: 'Neu',
        type: 'bar',
        barWidth: '5px',
        itemStyle: {
          color: deepickColors.new,
          barBorderRadius: 10
        },
        data: dataNew,
        stack: arbitraryStackKey,
        emphasis: { itemStyle: { color: deepickColors.new } },
        z: 10
      },
      {
        name: 'Zuschlag',
        type: 'bar',
        barWidth: '5px',
        itemStyle: {
          color: deepickColors.award,
          barBorderRadius: 10
        },
        data: dataAward,
        stack: arbitraryStackKey,
        emphasis: { itemStyle: { color: deepickColors.award} },
        z: 10
      },
      {
        name: 'Insolvenz',
        type: 'bar',
        barWidth: '5px',
        itemStyle: {
          color: deepickColors.insolvency,
          barBorderRadius: 10
        },
        data: dataInsolvency,
        stack: arbitraryStackKey,
        emphasis: { itemStyle: { color: deepickColors.insolvency} },
        z: 10
      }
    ],
    grid: { right: 5, left: 10, top: 0, bottom: 0 }
  };
};

const buildQuery = (start) => {
return `
PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
PREFIX insolvenzen: <https://deepick.eu/schema/insolvenzen/v1/pred#>
PREFIX insolvenzen-type: <https://deepick.eu/schema/insolvenzen/v1/type#>

SELECT ?year ?month ?day (COUNT (?publication) AS ?numberOfPublications) (COUNT (?processStageNew) AS ?numberOfProcessStageNew) (COUNT (?processStageAward) AS ?numberOfProcessStageAward) ?numberOfInsolvenz {
  ?resolveResult kg:publication ?publication.
  ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.
  BIND(year(xsd:dateTime(?kdqLastmod)) AS ?year)
  BIND(month(xsd:dateTime(?kdqLastmod)) AS ?month)
  BIND(day(xsd:dateTime(?kdqLastmod)) AS ?day)

  OPTIONAL {
    ?publication at-kdvo-raw:processStage ?processStageNew.
    FILTER(?processStageNew = "new")
  }

  OPTIONAL {
    ?publication at-kdvo-raw:processStage ?processStageAward.
    FILTER(?processStageAward = "award")
  }

  OPTIONAL {
    SELECT ?year ?month ?day (COUNT(?insolvenz) AS ?numberOfInsolvenz) {
      ?insolvenz rdf:type insolvenzen-type:insolvenz;
      insolvenzen:Dat_Ori_Bekanntmachung ?Dat_Ori_Bekanntmachung.
      BIND(year(xsd:dateTime(?Dat_Ori_Bekanntmachung)) AS ?year)
      BIND(month(xsd:dateTime(?Dat_Ori_Bekanntmachung)) AS ?month)
      BIND(day(xsd:dateTime(?Dat_Ori_Bekanntmachung)) AS ?day)
      FILTER (xsd:dateTime(?Dat_Ori_Bekanntmachung) > '${start.toISOString()}'^^xsd:dateTime)
    }
    GROUP BY ?year ?month ?day
  }
  FILTER (?kdqLastmod > '${start.toISOString()}'^^xsd:dateTime)
}
GROUP BY ?year ?month ?day
ORDER BY ?year ?month ?day
`
}

const LastSevenDays = ({ updateRequest }) => {
  const [data, setData] = useState([]);
  const [totalPublications, setTotalPublications] = useState();
  const [medianPublications, setMedianPublications] = useState();
  const { isDark } = useContext(AppContext);

  let yMax = (medianPublications * 2);
  const dataBackground = data.map(() => yMax);

  useEffect(() => {
    function refreshData()
    {
      let start = moment(new Date()).add(-6, 'd');
      sparql.query({ query: buildQuery(start)})
      .then(result => {
          setData(result)
          setTotalPublications(result.map(d => d.numberOfPublications).reduce((total, currentValue) => total + (currentValue != undefined ? currentValue : 0), 0));
          setMedianPublications(median(result.map(a => a.numberOfPublications)));
      });
      ;
    }
    console.log('updateRequest lastSevenDays');
    refreshData();
  }, [updateRequest])

  return (    
    <Card style={{ height: "170px"}}>
      <FalconCardHeader
        className="pb-0 "
        title={
          <Fragment>
            Letzte 7 Tage{' '}
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              transform="shrink-1"
              className="text-400"
              id="lastSevenDaysTooltip"
            />
            <UncontrolledTooltip placement="bottom" target="lastSevenDaysTooltip">
              Berechnet aufgrund der Daten der letzten sieben Tage
            </UncontrolledTooltip>
          </Fragment>
        }
        light={false}
        titleTag="h6"
      />
      <CardBody >
        <Row noGutters className="h-100 justify-content-between">
          <Col xs={6} className="col-xxl pr-2">
            <div className="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">
              {totalPublications ? numberFormatter(totalPublications, 0) : '...'}
            </div>
            <div className="fs--2 mt-3">
              <LastSevenDaysItem value={ data.map(d => d.numberOfProcessStageNew).reduce((total, currentValue) => total + (currentValue != undefined ? currentValue : 0), 0) } color={deepickColors.new} name="Neu" />
              <LastSevenDaysItem value={ data.map(d => d.numberOfProcessStageAward).reduce((total, currentValue) => total + (currentValue != undefined ? currentValue : 0), 0) } color={deepickColors.award} name="Zuschlag" />
              <LastSevenDaysItem value={ data.map(d => d.numberOfInsolvenz).reduce((total, currentValue) => total + (currentValue != undefined ? currentValue : 0), 0) } color={deepickColors.insolvency} name="Insolvenzen" />
            </div>
          </Col>
          <Col xs={4} className=" ">
            <div className="position-relative">
            <ReactEchartsCore
              echarts={echarts}
              option={getOption(data, dataBackground, isDark)}
              style={{ width: '100%', height: '100%' }}
            />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

LastSevenDays.propTypes = { updateRequest: PropTypes.any };

export default LastSevenDays;