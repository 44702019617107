
// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export function makeUuid()
{
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16).toUpperCase();
    });
}

function currentTime()
{
    return (new Date()).toISOString();
}

export async function query({ query, collection, bindings }) {
    const formData = new URLSearchParams();

    formData.append('query', query);
    if (collection) {
        formData.append('collection', collection);
    }

    for (const key in bindings) {
        if (Array.isArray(bindings[key])) {
            bindings[key].forEach(value => formData.append('bind:' + key, value));
        } else {
            formData.append('bind:' + key, bindings[key]);
        }
    }


    let response = await fetch('/v1/graphs/sparql',
                               {
                                   method: 'POST',
                                   headers: {
                                       'Content-Type': 'application/x-www-form-urlencoded'
                                   },
                                   body: formData.toString()
                               });
    response = await response.json();
    return response.results.bindings.map((row) => {
        for (let key in row) {
            let { type, datatype, value } = row[key];
            switch(datatype) {
            case "http://www.w3.org/2001/XMLSchema#boolean":
                value = (value === 'true');
                break;
            case "http://www.w3.org/2001/XMLSchema#integer":
                break;
            case "http://www.w3.org/2001/XMLSchema#decimal":
                break;
            case "http://www.w3.org/2001/XMLSchema#unsignedLong":
                value = parseInt(value);
                break;
            case "http://www.w3.org/2001/XMLSchema#dateTime":
                break;
            case "http://www.w3.org/2001/XMLSchema#date":
                break; /* date and dateTime will be left as strings */
            case undefined:
                break; /* strings */
            default:
                console.log("Warning, unmapped data type", datatype, "in sparql response");
            }
            row[key] = value;
        }
        return row;
    });
}

export async function query2({ query, collection, bindings }) {
    const formData = new URLSearchParams();
    formData.append('query', query);
    if (collection) {
        formData.append('collection', collection);
    }
    for (const key in bindings) {
        if (Array.isArray(bindings[key])) {
            bindings[key].forEach(value => formData.append('bind:' + key, value));
        } else {
            formData.append('bind:' + key, bindings[key]);
        }
    }

    let response = await fetch('/v1/graphs/sparql',
                               {
                                   method: 'POST',
                                   headers: {
                                       'Content-Type': 'application/x-www-form-urlencoded'
                                   },
                                   body: formData.toString()
                               });
    response = await response.json();
    return response.results.bindings.map((row) => {
        for (let key in row) {
            let { datatype, value } = row[key];
            switch(datatype) {
            case "http://www.w3.org/2001/XMLSchema#boolean":
                value = (value === 'true');
                break;
            case "http://www.w3.org/2001/XMLSchema#integer":
            case "http://www.w3.org/2001/XMLSchema#unsignedLong":
                value = parseInt(value);
                break;
            case "http://www.w3.org/2001/XMLSchema#dateTime":
            case "http://www.w3.org/2001/XMLSchema#date":
                break; /* date and dateTime will be left as strings */
            case undefined:
                break; /* strings */
            default:
                console.log("Warning, unmapped data type", datatype, "in sparql response");
            }
            row[key] = value;
        }
        return row;
    });
}

export async function updateQuery({ query, collection, bindings }) {
    const formData = new URLSearchParams();
    // console.log('query ', query);

    formData.append('query', query);
    if (collection) {
        formData.append('collection', collection);
    }
    for (const key in bindings) {
        formData.append('bind:' + key, bindings[key]);
    }

    const updateHeaders = new Headers();
    updateHeaders.append('Content-Type', 'application/sparql-update');
    updateHeaders.append('Accept', 'application/sparql-results+xml');

    let response = await fetch('/v1/graphs/sparql',
                               {
                                   method: 'POST',
                                   headers: updateHeaders,
                                   body: query
                               });
    response = await response;
    // return response.results.bindings.map((row) => {
    //     for (let key in row) {
    //         let { type, datatype, value } = row[key];
    //         switch(datatype) {
    //         case "http://www.w3.org/2001/XMLSchema#boolean":
    //             value = (value === 'true');
    //             break;
    //         }
    //         row[key] = value;
    //     }
    //     return row;
    // });
}