import React, { useContext, useState, useEffect } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../context/Context';
import SparqlHook from '../../SparqlHook.js';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { niceDate } from '../../helpers/utils';

import { Fragment } from 'react';
import { tableOverlays } from '../../config';
import { OrganizationLogo } from "../common/OrganizationLogo";

const MarketInsolvencies = (props) => {
  const { isDark } = useContext(AppContext);
  const [rowData, setRowData] = useState();
  const { runQuery } = SparqlHook();


  const renderContractor = (params) => {
    if(params.value) {
      let link = '/bieter/' + params.value.replace('urn:uuid:', '');
      return <Link to={link}>{params.data.contractorName}</Link>
    } else {
      return <Fragment></Fragment>
    }      
  }

  const getNiceDate = (params) => {
    return niceDate(params.value, 1);
  };

  const renderLogo = (params) => {    
      return <OrganizationLogo iri={params.data.contractor} editorMode={false} size={'35px'} frameSize={'40px'} />
  }

  const [columns] = useState([
    { field: "contractor", headerName: '', cellRenderer: renderLogo, flex: 1 },
    { field: "contractor", headerName: 'Bieter', cellRenderer: renderContractor, flex: 5 },    
    { field: "Dat_Ori_Bekanntmachung", headerName: 'Zuletzt', valueFormatter: getNiceDate, flex: 2 },
    { field: "countPublication", headerName: 'Zuschläge', flex: 2 }
  ]);


  function loadData() {
      let industryFilter = '';      
      if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') { industryFilter = `FILTER (?labelGroup = "${props.filteredIndustry}")`}
      
      let queryString = `
        PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
        PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
        PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
        PREFIX insolvenzen: <https://deepick.eu/schema/insolvenzen/v1/pred#>
        PREFIX insolvenzen-type: <https://deepick.eu/schema/insolvenzen/v1/type#>
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

        SELECT ?edikt ?firmenBuchNummer ?Dat_Ori_Bekanntmachung ?ediktName ?contractor ?contractorName ?labelGroup ?labelName (COUNT(?publication) AS ?countPublication)
        {
        ?edikt rdf:type insolvenzen-type:insolvenz;
            insolvenzen:Schuldner_Akt_Vorname ?ediktName;
            insolvenzen:Schuldner_Akt_FBNr ?firmenBuchNummer;
            insolvenzen:Dat_Ori_Bekanntmachung ?Dat_Ori_Bekanntmachung.
        ?contractor core:firmenbuchNummer ?firmenBuchNummer;
            core:name ?contractorName.
        OPTIONAL
        {
            ?resolveResult kg:contractor ?contractor.
            ?resolveResult kg:publication ?publication.
            ?publication at-kdvo-raw:cpvMain ?cpvMain.        
            ?label2cpvId core:cpv ?cpvMain.
            ?label2cpvId core:idLabel ?labelId.
            ?labelId core:name ?labelName.
            ?labelId core:group ?labelGroup.                 
        }
        ${industryFilter}
        }
        GROUP BY ?firmenBuchNummer
        ORDER BY DESC(?Dat_Ori_Bekanntmachung)
        LIMIT 10
      `      
      runQuery({ query: queryString})
      .then(result => {
        setRowData(result);
      });
    }

  useEffect(() => {
    loadData();
  }, [props.filteredIndustry, props.updateRequest])

  return (    
    <Fragment>      
          <Card>
            <FalconCardHeader
              className="pb-0"
              title={
              <Fragment>
                  <span style={{ display: 'inline' }}>Insolvenzen&nbsp;</span>
                  <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="text-400"
                  id="MarketInsolvenciessTooltip"
                  style={{ display: 'inline' , verticalAlign: 'top'}}
                  />
                  <UncontrolledTooltip placement="bottom" target="MarketInsolvenciessTooltip">
                  Bieter, die in Insolvenz-Edikten der Justiz genannt wurden. Die neuesten 10 werden angezeigt.
                  </UncontrolledTooltip>
              </Fragment>
              }
              light={false}
              titleTag="h6"
            >                          
            </FalconCardHeader>
            {
                <Card>
                    <CardBody>
                        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                            <AgGridReact
                            rowData={rowData}
                            defaultColDef={{sortable: true, filter: true, resizable: true }}              
                            columnDefs={columns}
                            overlayNoRowsTemplate={tableOverlays.overlayNoRowsTemplate}
                            overlayLoadingTemplate={tableOverlays.overlayLoadingTemplate}
                            domLayout='autoHeight'>              
                            </AgGridReact>
                        </div>
                    </CardBody>                
                </Card>
            }
            
          </Card>          
    </Fragment>
  );
};

export default MarketInsolvencies;

