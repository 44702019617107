import React, { useContext, useState, useEffect } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';
import moment from "moment";
import AppContext from '../../context/Context';
import SparqlHook from '../../SparqlHook.js';

import { Fragment } from 'react';

const MarketNetworkDiagram = (props) => {
  const { isDark } = useContext(AppContext);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  
  const { runQuery } = SparqlHook();
  let history = useHistory();

  const getOption = (isDark) => {

    const categories = [
      {
        name: "Auftraggeber",
        symbol: "circle"
      },
      {
        name: "Auftragnehmer",
        symbol: "circle",
      },
      {
        name: "Publikation",
        symbol: "diamond"
      },
      {
        name: "Anwalt oder Vergabeabwickler",
        symbol: "circle"
      }
    ];

    return {
        legend: {
            data: ['Auftraggeber', 'Auftragnehmer', 'Publikation', 'Anwalt oder Vergabeabwickler'],
            selected: {
              "Publikation": false
          }
          },
          series: [{
            type: 'graph',
            layout: 'force',
            // roam: true,
            animation: true,
            force: {
              
              repulsion: 200,
              gravity: 0.2
            },
            label: {  
              normal: {
                position: 'right',
                formatter: '{b}'
              }
            },
            draggable: true,


            emphasis: {
              focus: 'adjacency',
              label: {
                position: 'right',
                show: true
              }
            },
            roam: true,
            



            data: nodes,
            categories: categories,            
            edges: edges
          }]
    };
  };

  let processRawChartData = (lines) => {
    let nodes = {};
    let edges = new Map();
    let numberOfPublications = lines.length;

    const addEdge = (node1, node2) => {
      let key = node1 + "\0" +  node2;
      if (!edges.has(key)) {
        edges.set(key, 1);
      } else {
        edges.set(key, edges.get(key) + 1);
      }
    }

    const countContractor = (lines, contractor) => {
      return (lines.filter(x => x.contractor === contractor).length/numberOfPublications*200);
    }

    const countContractingBody = (lines, contractingBody) => {      
      return (lines.filter(x => x.contractingBody === contractingBody).length/numberOfPublications*200);
    }

    lines.forEach((line) => {
      // console.log('line', line);
      let { contractingBody, contractingBodyName, publication, contractorName, contractor,
             additionalContact, additionalContactName, title } = line;
      if (!nodes[contractingBody]) {
        nodes[contractingBody] = {
          id: contractingBody,
          name: contractingBodyName,
          symbolSize: countContractingBody(lines, contractingBody),
          value: 52,
          category: 0,
          label: { show: true }
        };
      }
      if (!nodes[publication]) {
        nodes[publication] = {
          id: publication,
          name: title,
          symbolSize: 6,
          value: 3,
          category: 2,
          label: { show: true }
        };
      }
      // if(contractingBody && !contractor) {        
        // addEdge(contractingBody, publication);
      // }
      if (contractor) {
        if (!nodes[contractor]) {
          nodes[contractor] = {
            id: contractor,
            name: contractorName,
            symbolSize: countContractor(lines, contractor),
            value: 5,
            category: 1,
            label: { show: true }
          }
        }
        addEdge(contractingBody, contractor);
        addEdge(contractor, publication);        
      }
      if (additionalContact && !nodes[additionalContact]) {
        nodes[additionalContact] = {
          id: additionalContact,
          name: additionalContactName,
          symbolSize: 2,
          value: 1,
          category: 3,
          label: { show: true }
        }
        addEdge(contractingBody, additionalContact);
        addEdge(additionalContact, publication);        
      }
    });
    setNodes(Object.values(nodes));
    var convertedEdges = [];
    for (let [key, value] of edges.entries()) {
      let [source, target] = key.split("\0");
      convertedEdges.push({ source, target, value: edges.get(key) });
    }
    if(convertedEdges) {
      setEdges(convertedEdges);
    }    
  };

  function loadData()
    {
      let industryFilter = '';      
      if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') { industryFilter = `FILTER (?labelGroup = "${props.filteredIndustry}")`}
      
      let from = moment(new Date()).add(props.days * -1, 'd');
      let filterFrom = 'FILTER (?downloaded >= "' + new Date(from).toISOString() + '"^^xsd:dateTime)';      

      let queryString = `
      PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
      PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
      PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
      PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>

      SELECT DISTINCT ?contractingBody ?contractingBodyName ?downloaded ?publication ?resolveResult ?contractorName ?contractor ?additionalContact ?additionalContactName ?additionalContactmainType ?title ?labelGroup ?labelName 
      WHERE {
          ?publication at-kdvo-raw:title ?title;
          at-kdvo-raw:downloaded ?downloaded;
            rdf:type ?type.
        ?resolveResult kg:publication ?publication.    
        ?resolveResult kg:contractor ?contractor.
        ?contractor core:name ?contractorName.      
  
        ?resolveResult kg:contractingBody ?contractingBody.
        ?contractingBody core:name ?contractingBodyName.

        
        OPTIONAL {
          ?publication at-kdvo-raw:cpvMain ?cpvMain.
          ?label2cpvId core:cpv ?cpvMain.
          ?label2cpvId core:idLabel ?labelId.
          ?labelId core:name ?labelName.
          ?labelId core:group ?labelGroup.  
        }

        OPTIONAL {
          ?resolveResult kg:additionalContact ?additionalContact.
          ?additionalContact core:name ?additionalContactName.
          ?additionalContact core:mainType ?additionalContactmainType.
        }

        FILTER (?type != <https://deepick.eu/schema/at-kdvo-raw/v1/type#publication>)

        ${industryFilter}
        ${filterFrom}
        FILTER (?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_7_2_Z1> || ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_2_Z1> || ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_2_Z2> || ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_1_Z4>)
      }
      GROUP BY ?publication ?contractor
      ORDER BY DESC(?downloaded) ?title
      # LIMIT 2000
      `      
      runQuery({ query: queryString})
      .then(result => {
        if(result && result.length > 0) {
          processRawChartData(result);
        }
        
      });
    }

  useEffect(() => {
    loadData();
  }, [props.filteredIndustry])

  function onChartDblClick(param,echarts) {

    let redirect = null;
    if(param.data?.target?.indexOf('https') > -1) { 
        redirect = '/publication?id=' + encodeURI(param.data.target.substring(param.data.target.indexOf('/kerndaten')));
    }
    if(param.data?.target?.indexOf('urn:uuid') > -1) { 
        redirect = encodeURI('/bieter/' + param.data.target.replace('urn:uuid:', ''));
    }

    if(param.data?.id?.indexOf('https') > -1) {        
        redirect = '/publication?id=' + encodeURI(param.data.id.substring(param.data.id.indexOf('/kerndaten')));
    }
    
    if(param.data?.id && (param.data.category === 0 || param.data.category === 3)) {
        redirect = '/auftraggeber/' + param.data.id.replace('urn:uuid:', '');
    }

    if(param.data?.id && param.data.category === 1) {
        redirect = '/bieter/' + param.data.id.replace('urn:uuid:', '');
    }

    if(redirect) {        
        history.push(redirect);
    }
  }

  function onChartClick(param,echarts) {
  }

    let onEvents = {
    // 'z': onChartClick.bind(this),
    'click': onChartClick.bind(this),
    'dblclick': onChartDblClick.bind(this)
  }


  return (    
    <Fragment>      
          <Card>
            <FalconCardHeader
              className="pb-0"
              title={
              <Fragment>
                  <span style={{ display: 'inline' }}>Alle Zuschläge&nbsp;</span>
                  <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="text-400"
                  id="MarketNetworkDiagramTooltip"
                  style={{ display: 'inline' , verticalAlign: 'top'}}
                  />
                  <UncontrolledTooltip placement="bottom" target="MarketNetworkDiagramTooltip">
                  Auftraggeber, Lieferanten und Vertretungen mit zugeordneten Veröffentlichungen in den letzten {props.days} Tagen. Nur Zuschläge.
                  </UncontrolledTooltip>
              </Fragment>
              }
              light={false}
              titleTag="h6"
            >                          
            </FalconCardHeader>
            {
              (nodes.length > 0 && edges.length > 0)
              ?
              <ReactEchartsCore echarts={echarts} option={getOption(isDark)} onEvents={onEvents} style={{ minHeight: '60.75rem' }} />
              :
              <Card>
                <CardBody>
                    Lädt ...
                </CardBody>                
              </Card>
            }
            
          </Card>          
    </Fragment>
  );
};

export default MarketNetworkDiagram;

