import { industriesToCaption } from "./utils";

let putPrefix = '';

export const getProfile = async user => {
    let profile = {};
    let profileUrl = `/v1/documents?uri=/user-profile/${user.user?.replace(/.*:/, '')}.json`;
    
    var request = new Request(
        profileUrl,
        {
            method: 'GET'
        });
    var response = await fetch(request);

    if(response.ok) {
      profile = await response.json();
    }
    return profile;
  }

const updateProfile = async profile => {    
    const updateP = async e => {
      let profileUrl = `/v1/documents?${putPrefix}uri=/user-profile/${profile.id?.replace(/.*:/, '')}.json`;
      var request = new Request(profileUrl, {
        method: 'PUT',
        body: JSON.stringify(profile)
      });
      var response = await fetch(request);
      if(response.ok) {}
    };
    await updateP();
  };

export async function mergeAndSubmit(currentUser, notificationRequest) {
  
    let profile = await getProfile(currentUser);
    let modified = false;
    let newProfile = false;
    let updatedProfile = profile;
    // console.log('update requested with profile', props.profile);
    // console.log('update requested with profile', props.profile?.id);
    if (!profile?.id) {
      updatedProfile = {
        id: currentUser.user,
        name: currentUser.name,
        createdOn: new Date()
      };
      putPrefix = 'perm:dive-user=read&perm:dive-user=update&';
      newProfile = true;
    }

    // no notifications: new
    if (!profile.notificationRequests || profile.notificationRequests?.length == 0) {
      updatedProfile.notificationRequests = [notificationRequest];
      modified = true;
    }

    if (profile.notificationRequests && profile.notificationRequests?.length > 0) {
      let found;
      if(notificationRequest.requestType === 'newPublicationActivityType') {
        found = profile.notificationRequests.find(
          request =>
            request.requestType == notificationRequest.requestType &&
            request.activityType == notificationRequest.activityType &&
            request.industry == notificationRequest.industry
        );
      } else {
        found = profile.notificationRequests.find(
          request =>
            request.requestType == notificationRequest.requestType &&
            request.search == notificationRequest.search &&
            request.industry == notificationRequest.industry
        );
      }
    
      if (!found) {
        // not existing: push to existing
        updatedProfile.notificationRequests.push(notificationRequest);
        modified = true;
      } else {
        // console.log('ignored, existing during merge', notificationRequest.requestType, notificationRequest.search, notificationRequest.industry);        
      }
    }
    if (modified || newProfile) {
      await updateProfile(updatedProfile);
    }
  }