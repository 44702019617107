import React, { Fragment, useContext, useState, useEffect } from 'react';
import FalconCardHeader from '../common/FalconCardHeader';
import { Badge, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';

import {
  getGrays,
  themeColors,
  rgbaColor,
  getPosition,
  numberFormatter,
  numberFormatter2,
  forecastCompleteMonth,
  changeIndicatorColor,
  niceDate
} from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../context/Context';
import * as sparql from '../../sparql.js';
import moment from 'moment';

const buildPeriodQuery = (start, end) => {
  return `
PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
PREFIX at-kdvo-raw-type: <https://deepick.eu/schema/at-kdvo-raw/v1/type#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

SELECT (COUNT (?publication) AS ?numberOfPublications)
{
  ?publication rdf:type at-kdvo-raw-type:publication;
    at-kdvo-raw:kdqLastmod ?kdqLastmod.

  FILTER (?kdqLastmod > '${start.toISOString()}'^^xsd:dateTime)
  FILTER (?kdqLastmod < '${end.toISOString()}'^^xsd:dateTime)
}
`;
};

// data for up/down indicator badge
let current12MonthPeriodEnd = new Date();
let current12MonthPeriodStart = moment(current12MonthPeriodEnd).add(-12, 'M');
let last12MonthPeriodEnd = current12MonthPeriodStart;
let last12MonthPeriodStart = moment(last12MonthPeriodEnd).add(-12, 'M');
let queryCurrent = buildPeriodQuery(current12MonthPeriodStart, current12MonthPeriodEnd);
let queryLast = buildPeriodQuery(last12MonthPeriodStart, last12MonthPeriodEnd);

const getOption = (data, isDark) => {
  const grays = getGrays(isDark);
  let lastComplete = undefined;
  let currentMonth = undefined;
  let completedMonthsData = [];
  let currentMonthData = [];

  if (data && data[0] && data.length > 0) {
    if (data.length > 1) {
      lastComplete = data[data.length - 2].numberOfPublications;
      currentMonth = data[data.length - 1].numberOfPublications;
      completedMonthsData = data.map(a => a.numberOfPublications);
      completedMonthsData[data.length - 1] = undefined;
      currentMonthData = data.map(a => undefined);
      currentMonthData[data.length - 2] = lastComplete;
      currentMonthData[data.length - 1] = forecastCompleteMonth(currentMonth);
    }
  }

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(params) {
        // console.log('params', params);
        if ((params[0].value && !params[1].value) || (params[0].value && params[1].value)) {
          // Regular value
          return `<strong>${params[0].name}:</strong> ${params[0].value}`;
        } else if (!params[0].value && params[1].value) {
          return `<strong>${params[0].name} Hochrechnung:</strong> ${Math.round(params[1].value)}`;
        }
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'category',
      data: data.map(a => a.month + '-' + a.year),
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'line',
        lineStyle: {
          color: themeColors.primary,
          width: 3
        },
        itemStyle: {
          color: grays['100'],
          borderColor: themeColors.primary,
          borderWidth: 2
        },
        hoverAnimation: true,
        data: completedMonthsData,
        connectNulls: true,
        smooth: 0.6,
        smoothMonotone: 'x',
        symbol: 'circle',
        // symbolSize: 3,
        symbolSize: 0,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(themeColors.primary, 0.25)
              },
              {
                offset: 1,
                color: rgbaColor(themeColors.primary, 0)
              }
            ]
          }
        }
      },
      {
        type: 'line',
        lineStyle: {
          color: themeColors.secondary,
          width: 3
        },
        itemStyle: {
          color: grays['100'],
          borderColor: themeColors.secondary,
          borderWidth: 2
        },
        hoverAnimation: true,
        data: currentMonthData,
        connectNulls: true,
        smooth: 0.6,
        smoothMonotone: 'x',
        symbol: 'circle',
        // symbolSize: 3,
        symbolSize: 0,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(themeColors.secondary, 0.25)
              },
              {
                offset: 1,
                color: rgbaColor(themeColors.secondary, 0)
              }
            ]
          }
        }
      }
    ],
    grid: { bottom: '2%', top: '2%', right: '10px', left: '10px' }
  };
};

let queryString = `
PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

SELECT ?year ?month (COUNT (?publication) AS ?numberOfPublications)
{
  ?resolveResult kg:publication ?publication.
  ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.
  BIND(YEAR(xsd:dateTime(?kdqLastmod)) AS ?year)
  BIND(MONTH(xsd:dateTime(?kdqLastmod)) AS ?month)
  FILTER (?kdqLastmod >= "2019-03-01T22:00:00.000Z"^^xsd:dateTime)
}
GROUP BY ?year ?month
ORDER BY ?year ?month
`;

const PublicationChart = ({ updateRequest }) => {
  const { isDark } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [change, setChange] = useState();

  useEffect(() => {
    function refreshData() {
      sparql.query({ query: queryString }).then(result => {
        setData(result);
        setTotal(result?.map(d => d.numberOfPublications).reduce((total, currentValue) => total + currentValue, 0));
      });
      let current;
      let last;

      sparql.query({ query: queryCurrent }).then(result => {
        current = result[0].numberOfPublications;
        sparql.query({ query: queryLast }).then(result => {
          last = result[0].numberOfPublications;
          setChange((current / last) * 100);
        });
      });
    }
    refreshData();
  }, [updateRequest]);

  return (
    <Card style={{ height: '170px' }}>
      <FalconCardHeader
        className="pb-0 pr-3 pl-3"
        title={
          <Fragment>
            Veröffentlichungen{' '}
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              transform="shrink-1"
              className="text-400"
              id="PublicationChartTooltip"
            />
            <UncontrolledTooltip placement="bottom" target="PublicationChartTooltip">
              Aktuelles Monat hochgerechnet. Auf/Ab Indikator auf im Vergleich der letzten 12 Monate (
              {niceDate(current12MonthPeriodStart)} - {niceDate(current12MonthPeriodEnd, true)}) zum 12 Monate Zeitraum
              davor ({niceDate(last12MonthPeriodStart)} - {niceDate(last12MonthPeriodEnd)})
            </UncontrolledTooltip>
          </Fragment>
        }
        light={false}
        titleTag="h6"
      >
        <div className="fs--1">
          <Badge pill color={changeIndicatorColor(change)} className="fs--2">
            <FontAwesomeIcon icon={(change > 100) ? 'caret-up' : 'caret-down'} className="mr-1" />
            {change ? numberFormatter(change, 0) : '...'}%
          </Badge>
        </div>
      </FalconCardHeader>
      <CardBody className="pb-2 pr-2 pl-2">
        <ReactEchartsCore
          echarts={echarts}
          option={getOption(data, isDark)}
          style={{ width: '100%', height: '100%' }}
        />

        <div className="fs-4 text-300" style={{ position: 'absolute', bottom: '10%', right: '8%' }}>
          {total ? total.toLocaleString() : '...'}
        </div>
      </CardBody>
    </Card>
  );
};

export default PublicationChart;