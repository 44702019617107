import React, { Fragment, useEffect, useState } from 'react';
import { Badge, Row, Col, UncontrolledTooltip, CardGroup } from 'reactstrap';
import SparqlHook from '../../SparqlHook.js';

import ContractorCardNewPrint from '../organization/ContractorCardNewPrint';
import orderBy from "lodash/orderBy";

import { OrganizationLogo } from '../common/OrganizationLogo';

const industry = 'Tech';
const start = new Date('2022-03-31T23:00:00.000Z');
const end = new Date('2023-03-31T21:59:59.999Z');
const size = '100px';

const TestPrint = (props) => {
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const { runQuery } = SparqlHook();
    
    useEffect(() => {
        let queryString = `
        PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
        PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
        PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
        PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
    
        SELECT ?contractor ?contractorName (SUM(?totalValue) AS ?sumOfTotalValue) (max(?downloaded) AS ?lastPublication) (count(?publication) AS ?numberOfPublication) ?websiteDomain
        WHERE {
          ?resolveResult kg:publication ?publication.
          ?publication at-kdvo-raw:downloaded ?downloaded.
          ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.
          ?publication at-kdvo-raw:title ?title.
          ?publication at-kdvo-raw:totalValue ?totalValue.
          
            ?resolveResult kg:contractor ?contractor.
            ?contractor core:name ?contractorName.
           
              ?publication at-kdvo-raw:cpvMain ?cpvMain.
              ?label2cpvId <https://deepick.eu/schema/sql-import/v1/pred#cpv> ?cpvMain.
              ?label2cpvId <https://deepick.eu/schema/sql-import/v1/pred#idLabel> ?labelId.
              ?labelId <https://deepick.eu/schema/sql-import/v1/pred#group> "${industry}".    
         
              OPTIONAL {
                ?contractor core:websiteDomain ?websiteDomain.
              }
            
        }
        GROUP BY ?contractor 
        HAVING (

            xsd:dateTime(?lastPublication) < "${start.toISOString()}"^^xsd:dateTime && ?numberOfPublication > 5
        )  
        ORDER BY ?contractorName
        LIMIT 2000
    `;


    
    runQuery({ query: queryString }).then(result => {
        let localLabels = [];
        let s = {}
        for (const item of result) {
            const isDuplicate = localLabels.find((obj) => obj === item.labelName);
            if (!isDuplicate) {
              localLabels.push(item.labelName);
                s[item.labelName] = false
            }
        }
        localLabels = orderBy(localLabels);
        setLabels(localLabels);
        // props.setOffGridContractors(result.length);
      setData(result);
    });
  
    }, [])

    return (
            
              <Fragment>
                <div style={{ backgroundColor: 'white' }} className="mt-3 ml-3 mr-3">
                            
                {                             
                    data?.map((org, index) => (
                        <Fragment>
                            <img 
                                src={`/v1/documents?uri=/logo/${org.contractor?.replace(/.*:/, '')}.png`} 
                                style={{width: size, height: size, objectFit: 'scale-down'}}
                                className="mt-3 ml-3 mr-3"
                                alt={org.contractorName}
                                title={org.contractorName}
                                ></img>
                            
                        </Fragment>
                        /*
                        <Col style={{backgroundColor: 'white'}}>
                        
                            
                
                            
                             <ContractorCardNewPrint org={org} start={start} end={end} industry={industry} /> 
                            
                                                
                        </ Col>               
                        */
                    ))
                    }
            
            
            </div>
                                                    
                        
              </Fragment>
                                             
       
    );
};

export default TestPrint;