import React, { useState, useEffect, useContext, Fragment} from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
import {  
  Button
} from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import { getProfile } from '../../helpers/profile';

const CurrentKeywordList = props => {
  const { currentUser } = useContext(AppContext);
  const [gridData, setGridData] = useState([]);
  
  const delteNotificationRequest = (params) => {    
    let profileUrl = `/v1/documents?uri=/user-profile/${currentUser.user?.replace(/.*:/, '')}.json`;
    const get = async e => {
        let request = new Request(
            profileUrl,
            {
                method: 'GET'
            });
        let response = await fetch(request);
        let currentProfile = await response.json(); 
        let n = currentProfile.notificationRequests.filter(request => request.id !== params.data.id);        
        currentProfile.notificationRequests = n;
        var requestUpdate = new Request(
            profileUrl,
            {
                method: 'PUT',
                body: JSON.stringify(currentProfile)
            });
        var responseUpdate = await fetch(requestUpdate);        
        
        props.setUpdate(new Date().toISOString());
    }
    get();
  }
  
  // retrieve only organizations which are mainly contractingBody 
  const setGrid = (p) => {
    let keywords = [];

    if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') {
        keywords = p?.notificationRequests?.filter((n) => n.requestType === 'keyword' && n.industry === props.filteredIndustry);
    } else {
        keywords = p?.notificationRequests?.filter((n) => n.requestType === 'keyword');
    }    
    setGridData(keywords);
    
  }
  
  const renderDeleteNotificationRequest = (params) => {
    return (<Button onClick={() => delteNotificationRequest(params)} variant="primary" type="submit" size="sm">Löschen</Button>)
  }    

  const [notificationsColumnDefs] = useState([
    { field: "id", headerName: 'Löschen',  cellRenderer: renderDeleteNotificationRequest, flex: 1 },        
    { field: "search", headerName: 'Schlüsselwort', flex: 3 }    
  ]);

  useEffect(() => {
    let p;
    async function fetchProfile() {
      let p = await getProfile(currentUser);
      setGrid(p);      
    }
    fetchProfile();
    
   }, [props.update, props.filteredIndustry])

  return (
    <Fragment>      
        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
          <AgGridReact
            defaultColDef={{sortable: true, filter: true, resizable: true }}
            rowData={gridData}
            columnDefs={notificationsColumnDefs}
            overlayNoRowsTemplate={'<span>Keine eingestellt</span>'}
            domLayout='autoHeight'>
          </AgGridReact>
        </div>        
    </Fragment>

  );
};

export default CurrentKeywordList;