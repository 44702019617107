import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import SparqlHook from '../../SparqlHook.js';
import { niceDate } from '../../helpers/utils';

const BankruptcyWarning = props =>  {
    const { runQuery } = SparqlHook();
    const [bankcruptcy, setBankcruptcy] = useState([]);        
    let queryB = `
# BankruptcyWarning
PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
PREFIX insolvenzen: <https://deepick.eu/schema/insolvenzen/v1/pred#>
PREFIX insolvenzen-type: <https://deepick.eu/schema/insolvenzen/v1/type#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

SELECT DISTINCT ?edikt ?org ?name ?firmenBuchNummer ?Dat_Ori_Bekanntmachung
{
  BIND (<${props.iri}> AS ?org)
  ?edikt rdf:type insolvenzen-type:insolvenz;
    insolvenzen:Schuldner_Akt_Vorname ?name;
    insolvenzen:Schuldner_Akt_FBNr ?firmenBuchNummer;
    insolvenzen:Dat_Ori_Bekanntmachung ?Dat_Ori_Bekanntmachung.
  ?org core:firmenbuchNummer ?firmenBuchNummer.

  OPTIONAL {
      ?publication kg:contractor ?org.
      }
}
ORDER BY DESC(?Dat_Ori_Bekanntmachung)
`

    function refreshData()
    {
      runQuery({ query: queryB})
      .then(result => {
        setBankcruptcy(result)
    })
      ;
    }
    useEffect(() => {
        refreshData();
    }, [])

    return (
      <span>
          { bankcruptcy.length > 0
            ?
            <Alert key={0} color="warning">
                <b>Insolvenz-Warnung</b><br/>
                Datum Orginale Bekanntmachung: { niceDate(bankcruptcy[0].Dat_Ori_Bekanntmachung, true) }<br/>
                <a href={encodeURI(`https://edikte.justiz.gv.at/edikte/id/idedi8.nsf/suchedi?SearchView&subf=f&SearchOrder=4&SearchMax=5000&retfields=~%5BFN%5D=${ bankcruptcy[0].firmenBuchNummer}&ftquery=&query=${ encodeURI(bankcruptcy[0].firmenBuchNummer)}`)}>Eintrag in justiz.gv.at</a>
            </Alert>
            :
            <span></span>
          }
      </span>
    )
}
export default BankruptcyWarning;
