import React, { Fragment, useContext, useState, useEffect } from 'react';
import AppContext from '../../context/Context';
import RadarForContractor from './RadarForContractor.js'
import RadarForContractingBody from './RadarForContractingBody.js'

const Radar = ({updateRequest}) => {    
    const { currentUser, currentUserPic, industries } = useContext(AppContext);  
  return (
    <Fragment>
        { (!currentUser.mainType || currentUser.mainType === 'AN')
            ?
            <RadarForContractor></RadarForContractor>
            :
            <RadarForContractingBody></RadarForContractingBody>
        }        
    </Fragment>
  );
};

export default Radar;