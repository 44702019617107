import React, { useState, useEffect, useContext, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Collapse, UncontrolledTooltip} from 'reactstrap';
import moment from "moment";
import SparqlHook from '../../SparqlHook.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../context/Context.js';

import { niceDate, daysPassed, numberToT } from '../../helpers/utils.js';
import { getProfile } from '../../helpers/profile.js';

import { OrganizationLogo } from "../common/OrganizationLogo.js";

import RenderContractorType from "./RenderContractorType.js";
import Publications from '../publication/Publications';


const MyPeergroupPublications = props => {

    const [appliedFilter, setAppliedFilter] = useState({
        industries: [{ name: props.filteredIndustry }],
        initialized: true,
        contractingBodies: ['urn:uuid:3a199368-0b5c-4f16-a001-6e6ef8227872', 'urn:uuid:4ab03bcb-bd76-4d00-99c2-3885b03d7dbd'], // #todo
        searchIssued: new Date()
      });
    

        

    const { runQuery } = SparqlHook();
    const [isOpen, setIsOpen] = useState(false);
    const [update, setUpdate] = useState(new Date().toISOString());
    const [data, setData] = useState();
    
    
    
    const { currentUser } = useContext(AppContext);


    async function loadNews() {
        let p = await getProfile(currentUser);
        let orgQuery = '';
        let orgs;        
        let from = moment(new Date()).add(-90, 'd');
        let filterFrom = 'FILTER (?downloaded >= "' + new Date(from).toISOString() + '"^^xsd:dateTime)';      

        if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') {
          orgs = p?.notificationRequests.filter((n) => n.requestType === 'organization' && n.industry === props.filteredIndustry);
        } else {
          orgs = p?.notificationRequests.filter((n) => n.requestType === 'organization');
        }
        
          

        for (var i = 0; i < orgs.length; i++) {            
            orgQuery += `?contractingBody = <${orgs[i].search.toLowerCase()}> ${(orgs.length > 1 && i < orgs.length - 1) ? ' || ' : ''}`;    
          }
      
          let queryString = `
          PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
          PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
          PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
          PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
          PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
      
          SELECT 
              ?publication
              ?cpvMain
              ?labelGroup 
              ?labelName
              ?processStage 
              ?contractingBody 
              ?contractingBodyName 
              ?contractingBodyNamePublication
              ?title 
              (COUNT(DISTINCT ?contractingBody) AS ?numberOfContractingBody) 
              
              
              ?downloaded
              ?kdqLastmod
              ?shortDescription
              
              
              (COUNT(DISTINCT ?resolveResultsRelated) AS ?numberOfRelated)
              
      WHERE
              {
                ?publication at-kdvo-raw:downloaded ?downloaded.
                ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.
                ?publication at-kdvo-raw:processStage ?processStage.
                ?publication at-kdvo-raw:title ?title.
                ?publication at-kdvo-raw:contractingBody ?contractingBodyPublication.
                ?contractingBodyPublication at-kdvo-raw:officialName ?contractingBodyNamePublication.
                ?resolveResult kg:publication ?publication.
      
                OPTIONAL {
                  ?publication at-kdvo-raw:shortDescription ?shortDescription.        
                }
                          
                
                
                  ?resolveResult kg:contractingBody ?contractingBody.
                  ?contractingBody core:name ?contractingBodyName.
                
        
                
                OPTIONAL{
                  ?publication at-kdvo-raw:cpvMain ?cpvMain.
                }
      
                OPTIONAL{
                  ?resolveResult kg:label ?label.
                  ?label core:name ?labelName.
                  ?label core:group ?labelGroup.
                }
      
                
                OPTIONAL {
                  ?resolveResult kg:additionalContact ?additionalContact.
                }
      
                OPTIONAL {
                  ?resolveResult kg:tenderingProcedure ?tenderingProcedure.
                  ?resolveResultsRelated kg:tenderingProcedure ?tenderingProcedure.
                }
            
              FILTER(
                      ${orgQuery}
              )        
              
              ${(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') ? `FILTER (?labelGroup = "${props.filteredIndustry}")` : ''}
              ${filterFrom}
              FILTER (?processStage = "new" || ?processStage = "prePublication")
              
            }
          GROUP BY ?publication
          ORDER BY DESC(?downloaded)
          LIMIT 50
          `;          
          runQuery({ query: queryString})        
          .then(result => setData(result));
    }

    useEffect(() => {
        // loadNews();
        console.log('hello');
    }, [props.filteredIndustry])

    return (
    <Fragment>
        <br></br>
        <h6 style={{ display: 'inline' }}>Ausschreibungen&nbsp;</h6>
                <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="text-400"
                id="NewsCbTooltip"
                style={{ display: 'inline' , verticalAlign: 'top'}}
                />
                <UncontrolledTooltip placement="bottom" target="NewsCbTooltip">
                Ereignisse zum Auftraggeber.
                </UncontrolledTooltip>
        <br></br>

        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
            <Publications
                      title="Neue Veröffentlichungen meiner Peergroup"
                      toolTip="Veröffentlichungen passend zur Suche."
                      columns={[
                        { colId: 'downloadDate' },                        
                        { colId: 'contractingBodyLogo' },
                        { colId: 'contractingBody' },                    
                        { colId: 'stage' },
                        { colId: 'label' },
                        { colId: 'title' },
                        { colId: 'contractor' },
                        { colId: 'totalValue' },
                        { colId: 'numberOfFiles' }
                      ]}
                      filter={appliedFilter}
                      records={30}
                    />
        </div>
    </Fragment>
    );
};

export default MyPeergroupPublications;