import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import LastSevenDays from './LastSevenDays';
import IndustryShareChart from './IndustryShareChart';
import PublicationChart from './PublicationChart';
import InsolvenzenChart from './InsolvenzenChart';
import MarketReport from './MarketReport';

const DashboardAlt = () => {
const [updateRequest, setUpdateRequest] = useState('initial');

useEffect(() => {
  // const interval = setInterval(() => {
  //   let now = new Date();
  //   setUpdateRequest(now);
  // }, 60000);
  // return () => clearInterval(interval);
}, [updateRequest])

return (
    <Fragment>
      
      <Row className="mb-3">
        <Col >
          <MarketReport updateRequest={updateRequest}></MarketReport>
        </Col>
      </Row>

      {/*
      <Row noGutters>
        <Col lg={6} className="mb-3 pr-lg-2 mb-3">
          <PublicationsByMonth updateRequest={updateRequest} className="h-lg-100" />
        </Col>
        <Col lg={6} className="mb-3 pl-lg-2 mb-3">
          <InsolvenzenByMonth updateRequest={updateRequest} className="h-lg-100" />
        </Col>

        <Col lg={12} className="mb-3 ">
          <NewPublicationsDashboard updateRequest={updateRequest} records={20} className="h-lg-100" />
        </Col>

        <Col lg={12} className="mb-3 ">
          <NewContractorsDashboard updateRequest={updateRequest} records={20} className="h-lg-100" />
        </Col>        
      </Row>
      */}
      {/*
      <Row noGutters>
        <Col lg={12} className="mb-3 mb-3">
          <DashboardMap />
        </Col>
      </Row>

      <Row noGutters>
        <Col lg={6} xl={6} className="mb-3 pr-lg-2 mb-3">
          <BestSellingProducts products={products} />
        </Col>
        <Col lg={6} xl={6} className="mb-3 pl-lg-2">
          <SharedFiles products={topContractors} />
        </Col>
      </Row>

      <Row noGutters>
        <Col sm={12}>
          <Card className="mb-3">
          <FalconCardHeader title="Neue Veröffentlichungen" light={false}>
            {isSelected ? (
              <InputGroup size="sm" className="input-group input-group-sm">
                <CustomInput type="select" id="bulk-select">
                  <option>Bulk actions</option>
                  <option value="Refund">Refund</option>
                  <option value="Delete">Delete</option>
                  <option value="Archive">Archive</option>
                </CustomInput>
                <Button color="falcon-default" size="sm" className="ml-2">
                  Apply
                </Button>
              </InputGroup>
            ) : (
              <Fragment>
                <ButtonIcon icon="filter" transform="shrink-3 down-2" color="falcon-default" size="sm" className="mx-2">
                  Filter
                </ButtonIcon>
                <ButtonIcon icon="external-link-alt" transform="shrink-3 down-2" color="falcon-default" size="sm">
                  Export
                </ButtonIcon>
              </Fragment>
            )}
          </FalconCardHeader>
          <CardBody className="p-0">
            <PurchasesTable setIsSelected={setIsSelected} />
          </CardBody>
        </Card>
        </Col>
      </Row>

      <Row noGutters>
        <Col lg={6} className="mb-3 pr-lg-2 mb-3">
          <WhoAmI/>
          updateRequest {updateRequest.toString()}
        </Col>
        <Col lg={6} className="mb-3 pr-lg-2 mb-3">
          <RunningProjects projects={tenders} />
        </Col>
      </Row>
      */}
    </Fragment>
  );
};

export default DashboardAlt;
