import * as sparql from './sparql.js';

export async function whoAmI() {
    var body = new FormData();
    body.append('xquery', 'xdmp:get-current-user()');
    const response = await fetch(`/v1/eval`, { method: 'POST', body: body });
    var text = (await response.text()).replace(/\r/g, '');
    var lines = text.split(/\n/);
    return lines[lines.length - 3];
}

export async function databaseSize() {
    var body = new FormData();
    body.append('xquery', `
        declare default element namespace 'http://marklogic.com/xdmp/status/forest';
        fn:sum(for $forest-id in xdmp:database-forests(xdmp:database())
        return fn:sum(xdmp:forest-status($forest-id)/stands/stand/disk-size))
    `);
    const response = await fetch(`/v1/eval`, { method: 'POST', body: body });
    var text = (await response.text()).replace(/\r/g, '');
    var lines = text.split(/\n/);
    return lines[lines.length - 3];
}

export async function tripleCount() {
    let queryString = `
    SELECT
    (COUNT(?s) AS ?count)
    WHERE {
        ?s ?p ?v
    }
    `
    sparql.query({ query: queryString})
    .then(result => {
        // console.log('count', result[0].count);
        return result[0].count;
    });

}

export function resolver() {
    let queryString = `
    PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
    PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

    SELECT DISTINCT ?resolver
    WHERE {
      ?resolveResult kg:publication ?publication.
      ?resolveResult kg:resolver ?resolver.
    }
    ORDER BY ?resolver
    `
    sparql.query({ query: queryString})
    .then(result => {
        return result;
    });
}

export async function setResolveResultToModified(resolveResultId) {
    let userName = await whoAmI();
    let now = new Date().toISOString();
    let updateResolverQuery = `
        PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
        PREFIX kg-type: <https://deepick.eu/schema/kg/v1/type#>
        PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

        DELETE {
            GRAPH ?g {
                ?resolveResult kg:resolver ?anyResolver.
                ?resolveResult kg:modifiedByUserOn ?anyResolver.
            }
        }
        INSERT {
            GRAPH ?g {
                ?resolveResult kg:resolver <https://deepick.eu/schema/kg/v1/entity/${userName}>.
                ?resolveResult kg:modifiedByUserOn "${now}"^^xsd:dateTime.
            }
        }
        WHERE {
            BIND (
                <${resolveResultId}> AS ?resolveResult
            )
            GRAPH ?g {
                ?resolveResult kg:resolver ?anyResolver.
            }
        }
    `
    // console.log('query', updateResolverQuery);
    sparql.updateQuery({ query: updateResolverQuery }).then(promised => {
      });
}

export async function setResolved(resolveResultId, field, value) {
    let updateResolverQuery = `
        PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
        PREFIX kg-type: <https://deepick.eu/schema/kg/v1/type#>
        PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

        DELETE {
            GRAPH ?g {
                ?resolveResult kg:${field} ?anyResolver.
            }
        }
        INSERT {
            GRAPH ?g {
                ?resolveResult kg:${field} "${value}"^^xsd:boolean
            }
        }
        WHERE {
            BIND (
                <${resolveResultId}> AS ?resolveResult
            )
            GRAPH ?g {
                ?resolveResult kg:${field} ?anyResolver.
            }
        }
    `
    // console.log('query', updateResolverQuery);
    // console.log('setting resolver for', resolveResultId, 'user:', userName);
    sparql.updateQuery({ query: updateResolverQuery }).then(promised => {
      });
}

export async function deleteAllAutomaticResolverResults() {
    let deleteQuery = `
    PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
    PREFIX kg-type: <https://deepick.eu/schema/kg/v1/type#>

    DELETE {
        GRAPH ?g {
            ?resolveResult ?p ?o
        }
    }
    WHERE {
        GRAPH ?g {
            ?resolveResult ?p ?o
        }
    {
        SELECT ?resolveResult
            WHERE {
                ?resolveResult kg:resolver ?resolver.
                FILTER (?resolver = <https://deepick.eu/schema/kg/v1/entity/resolver>)
            }
        ## LIMIT 20
    }
    }
    `
    sparql.updateQuery({ query: deleteQuery }).then(promised => {
    });
}

export async function resolveUser(userName) {
    // ## FIXME GUID check
    if(userName !== '</html>' && userName && userName.length > 10) {
      let queryString = `
          PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
          PREFIX coreType: <https://deepick.eu/schema/sql-import/v1/type#>
          PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
          PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

          SELECT DISTINCT ?user ?name ?roles ?organization ?email ?active ?profile_picture ?defaultIndustry ?mainType ?deeplake ?invitation_code ?trial_ends_at ?customer
          WHERE {
            BIND(<urn:uuid:${userName}> AS ?user)
            ?user rdf:type coreType:user;
                core:name ?name;
                core:roles ?roles;                
                core:email ?email;
                core:active ?active.

                ?user core:customer_id ?customer.
                ?customer core:name ?organization.
                ?customer core:invitation_code ?invitation_code.
                
                OPTIONAL { ?user core:defaultIndustry ?defaultIndustry.}
                OPTIONAL { ?user core:profile_picture ?profile_picture.}
                OPTIONAL { ?user core:mainType ?mainType.}
                OPTIONAL { ?user core:deeplake ?deeplake.}                
                
                OPTIONAL { ?customer core:trial_ends_at ?trial_ends_at. }
          }
          `
          return await sparql.query({ query: queryString})
      }
  }