import React, { useState, useEffect, useContext, Fragment} from 'react';
import { Button, Card, CardBody, Collapse, UncontrolledTooltip} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../context/Context.js';
import AddContractingBody from './AddContractingBody.js';
import CurrentCbList from './CurrentCbList.js';
import ContractingBodiesAwards from './ContractingBodiesAwards.js';
import ContractingBodiesPublications from './ContractingBodiesPublications.js';
import MyPeergroupPublications from './MyPeergroupPublications.js';
import { faCaretDown, faCaretUp  } from '@fortawesome/free-solid-svg-icons'
const MyPeergroup = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [update, setUpdate] = useState(new Date().toISOString());
  const toggle = () => setIsOpen(!isOpen);  
  const [effectiveFrom, setEffectiveFrom] = useState(new Date().toISOString());  
  const { currentUser } = useContext(AppContext);

  useEffect(() => {    
   }, [props.filteredIndustry, update])

  return (
    <Fragment>
      <Card>        
        <FalconCardHeader
          className="pb-0"
          title={
            <Fragment>
              <span style={{ display: 'inline' }}>Meine Auftraggeber Peergroup&nbsp;</span>
              <FontAwesomeIcon
              icon={['far', 'question-circle']}
              transform="shrink-1"
              className="text-400"
              id="ContractingBodiesTooltip"
              style={{ display: 'inline' , verticalAlign: 'top'}}
              />
              <UncontrolledTooltip placement="bottom" target="ContractingBodiesTooltip">
              Sie werden über alle Aktivitäten dieser Auftraggeber in dieser Branche ({props.filteredIndustry}) am Laufenden gehalten.
              </UncontrolledTooltip>
            </Fragment>
            }
            light={false}
            titleTag="h6"
        >                          
        </FalconCardHeader>
        <CardBody>                            
          <Button onClick={toggle} style={{ marginBottom: '1rem', width: '9rem' }} className="glyphicon glyphicon-asteris" size="sm">Auswählen&nbsp;
            <Fragment>
              { 
              (isOpen)
              ?
              <FontAwesomeIcon icon={faCaretUp}/>
              :
              <FontAwesomeIcon icon={faCaretDown}/>
            }
          </Fragment>        
          </Button>
          <Collapse isOpen={isOpen}>
            <AddContractingBody setUpdate={setUpdate} update={update} currentUser={currentUser} filteredIndustry={props.filteredIndustry} effectiveFrom={effectiveFrom}></AddContractingBody>            
            <CurrentCbList setUpdate={setUpdate} update={update} filteredIndustry={props.filteredIndustry}></CurrentCbList>
          </Collapse>                    
          <MyPeergroupPublications filteredIndustry={props.filteredIndustry} update={update}></MyPeergroupPublications>
        </CardBody>
      </Card>      
    </Fragment>
  );
};

export default MyPeergroup;