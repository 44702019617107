import React, { Fragment, useState, useEffect } from 'react';
import { getQuery } from './publicationQueryBuilder';
import { getColumnsAGGrid  } from './publicationColumns';
import SparqlHook from '../../SparqlHook.js';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { tableOverlays } from '../../config';

const Publications = (props) => {
    const [rowData, setRowData] = useState();    
    const columns = getColumnsAGGrid(props);
    const { runQuery } = SparqlHook();
    
    function refreshData()
    {            
      runQuery({ query: getQuery(props)})        
      .then(result => {
        setRowData(result);          
          if(result) {
            setRowData(result);            
          }
      })
      ;
    }

    useEffect(() => {      
      // console.log('props.filter.searchIssued?.toString()', props.filter.searchIssued?.toString());
      if(props.filter.initialized) {        
        refreshData();            
      } else {
        setRowData([]);
      }
        
    }, [props.filter.searchIssued?.toString()])

  return (      
    <Fragment>
      <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
        <AgGridReact          
          rowData={rowData}
          defaultColDef={{ sortable: true, filter: true, resizable: true }}
          columnDefs={columns}
          overlayNoRowsTemplate={tableOverlays.overlayNoRowsTemplate}
          overlayLoadingTemplate={tableOverlays.overlayLoadingTemplate}
          domLayout="autoHeight"                  
        />
      </div>    
    </Fragment>
  );
};

export default Publications;