import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Section from './Section';
import Logo from '../navbar/Logo';

const Trends = () => (
<Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Logo />
        <Card className="text-center">
    <CardBody className="p-5">
      <div className="display-1 text-200 fs-error">425</div>
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        Diese Seite ist noch nicht fertig.
      </p>
      <hr />
      <p>
      Der Inhalt wird gerade implementiert. Diese Seite ist ein Platzhalter. Empfinden Sie das als Fehler?
        <a href="mailto:office@deepick.eu" className="ml-1">
          Kontaktieren Sie uns bitte
        </a>
        .
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon="home" className="mr-2" />
        Home
      </Link>
    </CardBody>
  </Card>
      </Col>
    </Row>
  </Section>
);

export default Trends;