import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Button, Row, Col } from 'reactstrap';
import SparqlHook from '../../SparqlHook.js';
import * as sparql from '../../sparql.js';
import SearchOrg from '../common/SearchOrg';
import { mergeAndSubmit } from '../../helpers/profile';
import { AlertMessage } from "../common/AlertMessage";
import { notificationExists } from '../../helpers/notifications';

const AddContractingBody = props => {    
  const { runQuery } = SparqlHook();
  const [alert, setAlert] = useState({});
  const [orgId, setOrgId] = useState();
  const [lastAction, setLastAction] = useState();
  
  function updateOrgId(e) {
    setOrgId(e);
    setAlert({});  
  }

  async function addNotificationOrg() {
    let industry = (props.filteredIndustry != 'Alle' && props.filteredIndustry != '' ? props.filteredIndustry : undefined);
    if(await notificationExists(props.currentUser, 'organization', industry, orgId)) {
        setAlert({message: "Dieser Auftraggeber ist bereits auf der Liste.", color: "warning"});
      } else {
        let query = `
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        prefix xsd: <http://www.w3.org/2001/XMLSchema#>
        PREFIX core-type: <https://deepick.eu/schema/sql-import/v1/type#>               
        PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
        SELECT ?org ?name ?mainType
        {
            BIND (<${orgId}> AS ?org)
            ?org rdf:type core-type:organization.
            ?org core:name ?name.
            ?org core:mainType ?mainType.    
        }
        `;
    runQuery({ query: query }).then(async result => {
      let notificationRequest = {
        id: sparql.makeUuid(),
        createdOn: new Date(),
        createdBy: 'dive',
        effectiveFrom: props.effectiveFrom,
        requestType: 'organization',
        search: orgId,
        industry: industry,
        title: result[0].name.replace(/\\/g, '\\'),
        active: true
      };      
      await mergeAndSubmit(props.currentUser, notificationRequest);
      setAlert({message: 'Neuer Auftraggeber auf das Radar genommen.', color: 'success'})
      let _lastAction = new Date().toISOString();
      props.setUpdate(_lastAction);
      setLastAction(_lastAction);
    });
      }
  }

  useEffect(() => {

   }, [props.update])

  return (
    <Fragment>
        <Row >
            <Col xs={9} md={9} lg={9} className="mb-3" ><SearchOrg id="orgId" onUpdate={updateOrgId} autoFocus={true} selected={orgId} placeholder={"Wählen Sie einen Auftraggeber ..."} mainType="AG"/></Col>
            <Col xs={3} md={3} lg={3} className="mb-3 " ><Button disabled={orgId == null} onClick={() => addNotificationOrg()} size="sm">Hinzufügen</Button></Col>
        </Row>
        <Row>          
          <Col xs={12} md={12} lg={12} className="mb-3 " >
          
          
            <AlertMessage alert={alert}></AlertMessage>
          
          </Col>
          
        </Row>
    </Fragment>
  );
};

export default AddContractingBody;