import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Button, Input, Label, Row, Col } from 'reactstrap';
import * as sparql from '../../sparql.js';
import { mergeAndSubmit } from '../../helpers/profile';
import { AlertMessage } from "../common/AlertMessage";
import { notificationExists } from '../../helpers/notifications';
import Flex from '../common/Flex';

const AddKeyword = props => {      
  const [alert, setAlert] = useState({});  
  const [keyword, setKeyword] = useState();

  async function addNotification() {
    let industry = (props.filteredIndustry != 'Alle' && props.filteredIndustry != '' ? props.filteredIndustry : undefined);
    console.log(props.currentUser, 'keyword', industry, keyword);
    if(await notificationExists(props.currentUser, 'keyword', industry, undefined, keyword)) {
        setAlert({message: "Dieses Schlüsselwort ist bereits auf der Liste.", color: "warning"});
      } else {
        
      let notificationRequest = {
        id: sparql.makeUuid(),
        createdOn: new Date(),
        createdBy: 'dive',
        effectiveFrom: props.effectiveFrom,
        requestType: 'keyword',
        search: keyword,
        industry: industry,
        title: keyword,
        active: true
      };      
      await mergeAndSubmit(props.currentUser, notificationRequest);
      setAlert({message: 'Neues Schlüsselwort auf das Radar genommen. Benachrichtigungen weden per E-Mail an Sie verschickt.', color: 'success'})
      props.setUpdate(new Date().toISOString());
    
      }
  }

  useEffect(() => {
    
   }, [])

  return (
    <Fragment>
        <Row >
            <Col xs={9} md={9} lg={9} className="mb-3" >
            <Label>Neues Schlüsselwort</Label>
                <Flex>
                  <Input
                    type="text"
                    autoComplete="off"
                    id="keyword"                    
                    className="mr-1" 
                    value={keyword}
                    onChange={({ target }) => setKeyword(target.value)}
                  />
                </Flex>
                <br></br>
                <Button disabled={keyword == null} onClick={() => addNotification()} size="sm">Hinzufügen</Button>
            </Col>
            
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} className="mb-3 " ><AlertMessage alert={alert}></AlertMessage></Col>
        </Row>
    </Fragment>
  );
};

export default AddKeyword;