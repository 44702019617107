import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Alert, CustomInput, Input, Label } from 'reactstrap';
// import uuid from 'uuid/v1';
import { v1 as uuid } from 'uuid';


export const AlertMessage = props => {  

  return (    
    <Fragment>
        { (props.alert?.message?.length > 0) ?                 
                <Alert key={props.alert.message} color={props.alert.color}>{props.alert.message}</Alert>
                : 
                <Fragment></Fragment>
        }
    </Fragment>
  );
};
