import React, {useEffect, useState} from "react";

export const OrganizationLogo = ({ iri, editorMode, size, frameSize }) => {
    const [state, setState] = useState('...');
    const timestamp = Date.now();
    const [imageUrl, setImageUrl] = useState(`/v1/documents?uri=/logo/${iri?.replace(/.*:/, '')}.png`);

    const paste = async e => {
        if (e?.clipboardData?.files?.length & editorMode) {
            const file = e.clipboardData.files[0];
            console.log('paste', file, 'iri', iri);
            var request = new Request(
                imageUrl,
                {
                    method: 'PUT',
                    body: await file.arrayBuffer()
                });
            var response = await fetch(request);

            setImageUrl(imageUrl + "#X");
            setState('loading');
            // console.log('logo uploaded', response);
        }
    }

    const selectLogo = () => {
        setState('activated');
    }

    const logoFailed = e => {
        setState('noLogo');
    }

    const logoLoaded = () => {
        setState('logoFound');
    }

    const logoReload = () => {
        let ts = Date.now();
        setState('');
        setImageUrl(`/v1/documents?uri=/logo/${iri?.replace(/.*:/, '')}.png#${ts}`);
    }

    const body = () => {
        switch (state) {
            case 'loading':
                return <span>Logo wird geladen</span>;
            case 'noLogo':
                return editorMode ? <span>Kein Logo vorhanden<br/>Klicken, um ein Logo einzufügen!</span> : '';
            case 'logoFound':
                // return editorMode ? <span>Klicken, um das Logo zu ersetzen!</span> : '';
                return '';
            case 'activated':
                return editorMode ? <span>Logo aus dem Clipboard einfügen!</span> : '';
        }
    }

    useEffect(() => {
        logoReload();
    },[iri, editorMode])

    return <div style={{ minWidth: frameSize, minHeight: frameSize, width: frameSize, height: frameSize, backgroundColor: 'white', display: 'flex', justifyContent: 'center'}}
        onPaste={paste}
        onClick={selectLogo}>
        <img src={imageUrl} 
             style={{ display: state === 'logoFound' ? 'block' : 'none', width: size, height: size, objectFit: 'scale-down' }}
             onError={logoFailed}
             onLoad={logoLoaded}
             onClick={logoReload}
             alt="logo"/>
        <span style={{ display: state === 'noLogo' ? 'block' : 'none', width: size, height: size }}
              onClick={logoReload}>
        </span>
        { body() }
    </div>;
}