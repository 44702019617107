import React, { useContext, useState, useEffect } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { Link } from 'react-router-dom';
import { tableOverlays } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";

import AppContext from '../../context/Context';
import SparqlHook from '../../SparqlHook.js';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Fragment } from 'react';
import { OrganizationLogo } from "../common/OrganizationLogo";

const MarketNewConnections = (props) => {
  const { isDark } = useContext(AppContext);  
  const [rowData, setRowData] = useState();
  
  const { runQuery } = SparqlHook();  

  const renderContractor = (params) => {
    if(params.value) {
      let link = '/bieter/' + params.value.replace('urn:uuid:', '');
      return <Link to={link}>{params.data.contractorName}</Link>
    } else {
      return <Fragment></Fragment>
    }      
  }

  const renderContractingBody = (params) => {
    if(params.value) {
      let link = '/auftraggeber/' + params.value.replace('urn:uuid:', '');
      return <Link to={link}>{params.data.contractingBodyName}</Link>
    } else {
      return <Fragment></Fragment>
    }      
  }

  const renderLogo = (params) => {    
      return <OrganizationLogo iri={params.data.contractor} editorMode={false} size={'35px'} frameSize={'40px'} />
  }

  const [columns] = useState([
    { field: "contractor", headerName: '', cellRenderer: renderLogo, flex: 2 },
    { field: "contractor", headerName: 'Bieter', cellRenderer: renderContractor, flex: 5 },    
    { field: "contractingBody", headerName: 'AG', cellRenderer: renderContractingBody, flex: 5 }
  ]);

  function loadData() {
      let industryFilter = '';      
      if(props.filteredIndustry != 'Alle' && props.filteredIndustry != '') { industryFilter = `FILTER (?labelGroup = "${props.filteredIndustry}")`}
      
      let from = moment(new Date()).add(props.days * -1, 'd');      

      let queryString = `
      PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
      PREFIX core-type: <https://deepick.eu/schema/sql-import/v1/type#>
      PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
      PREFIX kg-type: <https://deepick.eu/schema/kg/v1/type#>
      PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      PREFIX kdRaw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
      PREFIX kdRaw-type: <https://deepick.eu/schema/at-kdvo-raw/v1/type#>
      PREFIX at-kdvo-raw-type: <https://deepick.eu/schema/at-kdvo-raw/v1/type#>
      PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
      PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

      SELECT 
        ?contractingBody ?contractingBodyName ?contractor ?contractorName (min(?kdqLastmod) AS ?firstPublication)          
      {
        ## ?resolveResult kg:additionalContact ?organization.
        ?resolveResult kg:contractor ?contractor.
        ?resolveResult kg:publication ?publication.
        ?resolveResult kg:contractingBody ?contractingBody.
        
        ?contractor a core-type:organization.
        ?contractor core:name ?contractorName.
        ?contractingBody a core-type:organization.
        ?contractingBody core:name ?contractingBodyName.          

        ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.

        
        OPTIONAL {
          ?publication at-kdvo-raw:cpvMain ?cpvMain.
          ?label2cpvId core:cpv ?cpvMain.
          ?label2cpvId core:idLabel ?labelId.
          ?labelId core:name ?labelName.
          ?labelId core:group ?labelGroup.  
        }
                  
        ${industryFilter}
      }
     
      GROUP BY ?contractingBody ?contractor
      HAVING (
        xsd:dateTime(?firstPublication) > '${new Date(from).toISOString()}'^^xsd:dateTime
        )
      # ORDER BY (lcase(?contractingBodyName))
      ORDER BY DESC(?firstPublication)
      LIMIT 10
      `      
      runQuery({ query: queryString})
      .then(result => {
        // console.log('queryString', queryString);
        // console.log('result', result);
        if(result) {
                            
            setRowData(result)            
          
        }
        
      });
    }

  useEffect(() => {    
    loadData();
  }, [props.filteredIndustry, props.updateRequest])

  return (    
    <Fragment>      
          <Card>
            <FalconCardHeader
              className="pb-0"
              title={
              <Fragment>
                  <span style={{ display: 'inline' }}>Neue AG : Bieter Verhältnisse&nbsp;</span>
                  <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="text-400"
                  id="MarketNewConnectionsTooltip"
                  style={{ display: 'inline' , verticalAlign: 'top'}}
                  />
                  <UncontrolledTooltip placement="bottom" target="MarketNewConnectionsTooltip">
                  Bieter, die das erste Mal in einem Zuschlag eines Auftraggebers genannt wurden.
                  </UncontrolledTooltip>
              </Fragment>
              }
              light={false}
              titleTag="h6"
            >                          
            </FalconCardHeader>
            {                
              <CardBody>
                  <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                      <AgGridReact                      
                      defaultColDef={{sortable: true, filter: true, resizable: true }}              
                      columnDefs={columns}
                      rowData={rowData}
                      overlayNoRowsTemplate={tableOverlays.overlayLoadingTemplate}
                      overlayLoadingTemplate={tableOverlays.overlayLoadingTemplate}                      
                      domLayout='autoHeight'>              
                      </AgGridReact>
                  </div>
              </CardBody>
            }            
          </Card>          
    </Fragment>
  );
};

export default MarketNewConnections;

