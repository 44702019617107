import React, { Fragment, useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getGrays, getPosition, isIterableArray, numberFormatter, numberFormatter2 } from '../../helpers/utils';
import deepickColors from '../../helpers/standardColors';
import IndustryShareItem from './IndustryShareItem';
import { Badge, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';

import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';

import AppContext from '../../context/Context';
import * as sparql from '../../sparql.js';
import FalconCardHeader from '../common/FalconCardHeader';

const getOption = (data, isDark) => {
  const grays = getGrays(isDark);
  return {
    color: data.map(d => d.color),
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: grays.white,
      textStyle: { color: grays.black },
      transitionDuration: 0,
      borderColor: grays['300'],
      borderWidth: 1,
      formatter: function(params) {
        return `<strong>${params.data.name}:</strong> ${params.percent}%`;
      }
    },
    position(pos, params, dom, rect, size) {
      return getPosition(pos, params, dom, rect, size);
    },

    legend: { 
      show: false ,    
        orient: 'vertical',        
        left: 10                    
    },
    series: [
      {
        radius: '70%',
        center: ['50%', '50%'],
        type: 'pie',        
        avoidLabelOverlap: false,
        hoverAnimation: false,                
        label: {
          position: 'outer'
          
        },        
        top: -20,
        data: data
      }
    ]
  };
};

let queryString = `
PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
PREFIX raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

SELECT ?labelGroup (COUNT(?publication) AS ?numberOfPublications)
{
  ?publication rdf:type ?type.
  ?publication raw:downloaded ?downloaded.
  
  ?publication raw:processStage ?processStage.
  FILTER (?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#publication>)  

  OPTIONAL {
    ?publication raw:cpvMain ?cpvMain.
    ?label2cpvId core:cpv ?cpvMain.
    ?label2cpvId core:idLabel ?labelId.
    ?labelId core:name ?labelName.
    ?labelId core:group ?labelGroup.    
  }
  
 # FILTER (?downloaded >= "2022-10-13T22:00:00.000Z"^^xsd:dateTime)
}
GROUP BY ?labelGroup
`

const IndustryShareChart = ({updateRequest}) => {
  const [data, setData] = useState([]);
  const [totalShare, setTotalShare] = useState();
  const { isDark } = useContext(AppContext);

  function lookupColor(industry) {
    switch(industry) {
      case 'Allgemein':
        return deepickColors.allgemein;
        break;
      case 'Bau':
        return deepickColors.brancheBau;
        break;
      case 'Beratung':
        return deepickColors.brancheConsulting;
        break;
      case 'Tech':
        return deepickColors.brancheTech;
        break;
      default:
        return deepickColors.other;
    }
  }

  useEffect(() => {
    function refreshData()
    {
      sparql.query({ query: queryString})
      .then(result => {
          let chartData = [];
          result.map((item, index) => {
            let name = '';
            if (item.labelGroup) { name = item.labelGroup; }            
            if(name != '') {
              let newItem = {id: index, value: item.numberOfPublications, name: name, color: lookupColor(name)}
              chartData.push(newItem);
            }
          });

          setData(chartData);
          setTotalShare(chartData.map(d => d.value).reduce((total, currentValue) => total + currentValue, 0));
      });
      ;
    }
    refreshData();
  }, [updateRequest])

  return (
    <Card style={{ height: "170px"}}>
      <FalconCardHeader
        className="pb-0 pr-3 pl-3"
        title={
          <Fragment>
            Branchenverteilung{' '}
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              transform="shrink-1"
              className="text-400"
              id="IndustryShareChartTooltip"
            />
            <UncontrolledTooltip placement="bottom" target="IndustryShareChartTooltip">
              Berechnet auf Basis aller verfügbaren Veröffentlichungen mit einer Zuordnung
            </UncontrolledTooltip>
          </Fragment>
        }
        light={false}
        titleTag="h6"
      />
      <CardBody className="pb-2 pr-2 pl-2">
        <ReactEchartsCore
                echarts={echarts}
                option={getOption(data, isDark)}
                style={{ width: '100%', height: '100%' }}
              />                    

      </CardBody>
    </Card>
  );
};

export default IndustryShareChart;
