import React, { Fragment, useContext } from 'react';
import AppContext from '../../context/Context';
import { Typeahead } from 'react-bootstrap-typeahead';

const SearchOrg = ({ onUpdate, autoFocus, selected, placeholder, mainType }) => {        
  const { organizationLookupList } = useContext(AppContext);
  let organizationLookupListFiltered = (mainType) ? organizationLookupList.filter((o) => o.mainType === mainType) : organizationLookupList;
  function onChangeHandling(selection) {
    if(selection) {      
      onUpdate(selection[0]?.id);
    }    
  }  
  function onEnter(selected) {
    if(selected) {      
      onUpdate(selected);
    }    
  }    

  let selectedItem = [];
  if(organizationLookupList) {
    let foundItem = organizationLookupList.find(e => e.id === selected);      
    if(foundItem) { 
      selectedItem.push(foundItem);
    }  
  }

  
    
  return (         
          <Typeahead          
          id="basic-typeahead-single"
          labelKey="name"
          emptyLabel="Keine Suche oder nichts gefunden"
          onChange={onChangeHandling}
          minLength={2}          
          options={organizationLookupListFiltered}          
          selected={selectedItem}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onEnter(selected);              
            }
          }}
          placeholder={(placeholder ? placeholder : "Wählen Sie eine Organisation ...")}
        />
  );
};

export default SearchOrg;