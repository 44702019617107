import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Button, Card, CardBody, Collapse, UncontrolledTooltip} from 'reactstrap';
import { niceDate, daysPassed, numberToT } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faF, faFire  } from '@fortawesome/free-solid-svg-icons'

const RenderContractorType = ({ params }) => {    

    let test1 = new Date(params.data.downloaded);
    let test2 = new Date(params.data.minDownloadedCb);                        
    
  return (
    <Fragment>      
        { (daysPassed(test1, test2) < 2 && daysPassed(test1, new Date()) < 90)
        ?
        <Fragment> 
            <FontAwesomeIcon icon={faFire} style={{color: "red"}} id={"icon" + params.rowIndex}/> 
            <UncontrolledTooltip placement="bottom" target={"icon" + params.rowIndex}>NEU beim Kunden</UncontrolledTooltip>
        </Fragment>
        :        
        <Fragment></Fragment>                 
         }
         { (daysPassed(test1, test2) < 20 && daysPassed(test1, new Date()) > 90)
        ?
        <Fragment>
            <FontAwesomeIcon icon={faFire} style={{color: "orange"}} id={"icon" + params.rowIndex}/> 
            <UncontrolledTooltip placement="bottom" target={"icon" + params.rowIndex}>Erstmals beim Kunden</UncontrolledTooltip>
            </Fragment>
        :        
        <Fragment></Fragment>                 
}
{
    (!params.data.contractorName)
    ?
    <Fragment>
        <FontAwesomeIcon icon={faFire} style={{color: "blue"}} id={"icon" + params.rowIndex}/> 
        <UncontrolledTooltip placement="bottom" target={"icon" + params.rowIndex}>NEU im Markt</UncontrolledTooltip>    
    </Fragment>
    :
    <Fragment></Fragment>
}
         

</Fragment>


  );
};

export default RenderContractorType;